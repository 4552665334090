import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  SocialCamelJSON: any;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
};

export type AddItem = {
  __typename?: 'AddItem';
  status?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  shortMessage?: Maybe<Scalars['String']>;
  quantitaMax?: Maybe<Scalars['Int']>;
  _debug?: Maybe<DjangoDebug>;
};

export type AddItemInput = {
  item: Scalars['Int'];
  quantita?: Maybe<Scalars['Int']>;
};

export type AddItemWishlist = {
  __typename?: 'AddItemWishlist';
  wishlist?: Maybe<WishlistType>;
  status?: Maybe<Scalars['Boolean']>;
};

export type AddMultipleItems = {
  __typename?: 'AddMultipleItems';
  status?: Maybe<Scalars['Boolean']>;
};

export type AttributiProdottoType = {
  __typename?: 'AttributiProdottoType';
  annata?: Maybe<Scalars['String']>;
  vitigno?: Maybe<Scalars['String']>;
  abbinamento?: Maybe<Scalars['String']>;
  gradazione?: Maybe<Scalars['String']>;
  formato?: Maybe<Scalars['String']>;
  colore?: Maybe<Scalars['String']>;
  regione?: Maybe<Scalars['String']>;
  denominazione?: Maybe<Scalars['String']>;
  consumoIdeale?: Maybe<Scalars['String']>;
  servizio?: Maybe<Scalars['String']>;
  bicchiere?: Maybe<Scalars['String']>;
  degustazione?: Maybe<Scalars['String']>;
  provenienza?: Maybe<Scalars['String']>;
  abbinamentiGastronomici?: Maybe<Scalars['String']>;
};

export type BreadcrumbItemType = {
  __typename?: 'BreadcrumbItemType';
  titolo?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  absoluteUrl?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type BreadcrumbType = {
  __typename?: 'BreadcrumbType';
  titolo?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CantinaInfoBoxNode = {
  __typename?: 'CantinaInfoBoxNode';
  /** Es: e2780IjFa5A */
  idYoutube?: Maybe<Scalars['String']>;
  mp4?: Maybe<Scalars['String']>;
  ogg?: Maybe<Scalars['String']>;
  webm?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  tipo?: Maybe<TipoMediaNode>;
  ordinamento: Scalars['Int'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  cantina: CantineNode;
  testoTop: Scalars['String'];
  testoTopIt?: Maybe<Scalars['String']>;
  testoTopEn?: Maybe<Scalars['String']>;
  testoBottom: Scalars['String'];
  testoBottomIt?: Maybe<Scalars['String']>;
  testoBottomEn?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
};

export type CantineNode = Node & {
  __typename?: 'CantineNode';
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionIt?: Maybe<Scalars['String']>;
  seoDescriptionEn?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleIt?: Maybe<Scalars['String']>;
  seoTitleEn?: Maybe<Scalars['String']>;
  seoH1?: Maybe<Scalars['String']>;
  seoH1It?: Maybe<Scalars['String']>;
  seoH1En?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  alias: Scalars['String'];
  nome: Scalars['String'];
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  slugIt?: Maybe<Scalars['String']>;
  slugEn?: Maybe<Scalars['String']>;
  titolo?: Maybe<Scalars['String']>;
  titoloIt?: Maybe<Scalars['String']>;
  titoloEn?: Maybe<Scalars['String']>;
  sottotitolo?: Maybe<Scalars['String']>;
  sottotitoloIt?: Maybe<Scalars['String']>;
  sottotitoloEn?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  latitudine?: Maybe<Scalars['Float']>;
  longitudine?: Maybe<Scalars['Float']>;
  info1?: Maybe<Scalars['String']>;
  info1It?: Maybe<Scalars['String']>;
  info1En?: Maybe<Scalars['String']>;
  info2?: Maybe<Scalars['String']>;
  info2It?: Maybe<Scalars['String']>;
  info2En?: Maybe<Scalars['String']>;
  info3?: Maybe<Scalars['String']>;
  info3It?: Maybe<Scalars['String']>;
  info3En?: Maybe<Scalars['String']>;
  info4?: Maybe<Scalars['String']>;
  info4It?: Maybe<Scalars['String']>;
  info4En?: Maybe<Scalars['String']>;
  enologo?: Maybe<Scalars['String']>;
  enologoIt?: Maybe<Scalars['String']>;
  enologoEn?: Maybe<Scalars['String']>;
  recensioneEnologo?: Maybe<Scalars['String']>;
  recensioneEnologoIt?: Maybe<Scalars['String']>;
  recensioneEnologoEn?: Maybe<Scalars['String']>;
  statement?: Maybe<Scalars['String']>;
  statementIt?: Maybe<Scalars['String']>;
  statementEn?: Maybe<Scalars['String']>;
  recapiti?: Maybe<Scalars['String']>;
  recapitiIt?: Maybe<Scalars['String']>;
  recapitiEn?: Maybe<Scalars['String']>;
  sito?: Maybe<Scalars['String']>;
  fb?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  flickr?: Maybe<Scalars['String']>;
  linkVideo?: Maybe<Scalars['String']>;
  mailHospitality?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  boxTitolo?: Maybe<Scalars['String']>;
  boxTitoloIt?: Maybe<Scalars['String']>;
  boxTitoloEn?: Maybe<Scalars['String']>;
  boxSottotitolo?: Maybe<Scalars['String']>;
  boxSottotitoloIt?: Maybe<Scalars['String']>;
  boxSottotitoloEn?: Maybe<Scalars['String']>;
  boxTesto?: Maybe<Scalars['String']>;
  boxTestoIt?: Maybe<Scalars['String']>;
  boxTestoEn?: Maybe<Scalars['String']>;
  valoreAttributoCantina?: Maybe<ValoreAttributoNode>;
  valoreAttributoRegione?: Maybe<ValoreAttributoNode>;
  /** Visibile su sito Vinicum */
  vinicum: Scalars['Boolean'];
  /** Visibile su sito GIV */
  giv: Scalars['Boolean'];
  ordinamento: Scalars['Int'];
  ordinamentoGiv: Scalars['Int'];
  linee?: Maybe<Array<Maybe<LineaProdottoNode>>>;
  immagini: Array<ImageCantineNode>;
  video: Array<VideoCantineNode>;
  infoBox?: Maybe<Array<Maybe<CantinaInfoBoxNode>>>;
  visiteGiv: VisitaGivNodeConnection;
  pk?: Maybe<Scalars['Int']>;
  contentTypeId?: Maybe<Scalars['Int']>;
  prodotti?: Maybe<ProdottoNodeConnection>;
  url?: Maybe<Scalars['String']>;
  regione?: Maybe<ValoreAttributoNode>;
  mainImage?: Maybe<ImageCantineNode>;
  enologoImage?: Maybe<ImageCantineNode>;
  premioCantinaImage?: Maybe<ImageCantineNode>;
  visitaImage?: Maybe<ImageCantineNode>;
  videoImage?: Maybe<ImageCantineNode>;
  logo?: Maybe<ImageCantineNode>;
  logoTrasparente?: Maybe<ImageCantineNode>;
  cantinaSuccessiva?: Maybe<CantineNode>;
  listingVideo?: Maybe<Array<Maybe<VideoCantineNode>>>;
  idYoutubeLinkVideo?: Maybe<Scalars['String']>;
  adminUrl?: Maybe<Scalars['String']>;
  modelClass?: Maybe<Scalars['String']>;
  pagina?: Maybe<PaginaNode>;
  numeroProdotti?: Maybe<Scalars['Int']>;
  prodottiGiv?: Maybe<ProdottoNodeConnection>;
};


export type CantineNodeVisiteGivArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CantineNodeProdottiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CantineNodeProdottiGivArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CantineNodeConnection = {
  __typename?: 'CantineNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CantineNodeEdge>>;
};

/** A Relay edge containing a `CantineNode` and its cursor. */
export type CantineNodeEdge = {
  __typename?: 'CantineNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CantineNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CarrelloType = {
  __typename?: 'CarrelloType';
  items?: Maybe<Array<Maybe<RigaCarrelloNode>>>;
  prezzo?: Maybe<Scalars['Float']>;
  totaleScontato?: Maybe<Scalars['Float']>;
  totaleProdottiScontato?: Maybe<Scalars['Float']>;
  numProdotti?: Maybe<Scalars['Int']>;
  scontoAssoluto?: Maybe<Scalars['Float']>;
  scontoPercentuale?: Maybe<Scalars['Float']>;
  promoApplicata?: Maybe<PromozioneType>;
  sistemaSpedizione?: Maybe<SistemaSpedizioneType>;
  promoCode?: Maybe<Scalars['String']>;
  includiMessaggio?: Maybe<Scalars['Boolean']>;
  messaggio?: Maybe<Scalars['String']>;
  messaggioFirma?: Maybe<Scalars['String']>;
  correlati?: Maybe<Array<Maybe<ProdottoNode>>>;
  confezioniCorrelate?: Maybe<Array<Maybe<ProdottoNode>>>;
  limiteFreeShipping?: Maybe<Scalars['Float']>;
  iva?: Maybe<Scalars['Float']>;
  accise?: Maybe<Scalars['Float']>;
  spedizioneEstero?: Maybe<Scalars['Float']>;
  erroreApisEstero?: Maybe<Scalars['Boolean']>;
};

export type CategoriaEventoNode = {
  __typename?: 'CategoriaEventoNode';
  id: Scalars['ID'];
  intro: Scalars['String'];
  introIt?: Maybe<Scalars['String']>;
  introEn?: Maybe<Scalars['String']>;
  nome: Scalars['String'];
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  eventi?: Maybe<Array<Maybe<EventoNode>>>;
  pk?: Maybe<Scalars['Int']>;
};

export type CategoriaFaqNode = Node & {
  __typename?: 'CategoriaFaqNode';
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionIt?: Maybe<Scalars['String']>;
  seoDescriptionEn?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleIt?: Maybe<Scalars['String']>;
  seoTitleEn?: Maybe<Scalars['String']>;
  seoH1?: Maybe<Scalars['String']>;
  seoH1It?: Maybe<Scalars['String']>;
  seoH1En?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  alias: Scalars['String'];
  nome?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  chiave: Scalars['String'];
  voceMenu?: Maybe<Scalars['String']>;
  voceMenuIt?: Maybe<Scalars['String']>;
  voceMenuEn?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  slugIt?: Maybe<Scalars['String']>;
  slugEn?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  faqs: FaqNodeConnection;
  pk?: Maybe<Scalars['Int']>;
  faq?: Maybe<Array<Maybe<FaqNode>>>;
};


export type CategoriaFaqNodeFaqsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CategoriaFaqNodeConnection = {
  __typename?: 'CategoriaFaqNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoriaFaqNodeEdge>>;
};

/** A Relay edge containing a `CategoriaFaqNode` and its cursor. */
export type CategoriaFaqNodeEdge = {
  __typename?: 'CategoriaFaqNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CategoriaFaqNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CategoriaNewsNode = Node & {
  __typename?: 'CategoriaNewsNode';
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionIt?: Maybe<Scalars['String']>;
  seoDescriptionEn?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleIt?: Maybe<Scalars['String']>;
  seoTitleEn?: Maybe<Scalars['String']>;
  seoH1?: Maybe<Scalars['String']>;
  seoH1It?: Maybe<Scalars['String']>;
  seoH1En?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  alias: Scalars['String'];
  nome?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  slugIt?: Maybe<Scalars['String']>;
  slugEn?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  news: NewsNodeConnection;
  pk?: Maybe<Scalars['Int']>;
};


export type CategoriaNewsNodeNewsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  categoriaId_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CategoriaNewsNodeConnection = {
  __typename?: 'CategoriaNewsNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoriaNewsNodeEdge>>;
};

/** A Relay edge containing a `CategoriaNewsNode` and its cursor. */
export type CategoriaNewsNodeEdge = {
  __typename?: 'CategoriaNewsNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CategoriaNewsNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CategoriaNode = Node & {
  __typename?: 'CategoriaNode';
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionIt?: Maybe<Scalars['String']>;
  seoDescriptionEn?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleIt?: Maybe<Scalars['String']>;
  seoTitleEn?: Maybe<Scalars['String']>;
  seoH1?: Maybe<Scalars['String']>;
  seoH1It?: Maybe<Scalars['String']>;
  seoH1En?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  alias: Scalars['String'];
  nome: Scalars['String'];
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  chiave: Scalars['String'];
  sottoTitolo?: Maybe<Scalars['String']>;
  sottoTitoloIt?: Maybe<Scalars['String']>;
  sottoTitoloEn?: Maybe<Scalars['String']>;
  voceMenu: Scalars['String'];
  voceMenuIt?: Maybe<Scalars['String']>;
  voceMenuEn?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  slugIt?: Maybe<Scalars['String']>;
  slugEn?: Maybe<Scalars['String']>;
  parent?: Maybe<CategoriaNode>;
  prodotti?: Maybe<Array<Maybe<ProdottoNode>>>;
  valoreAttributo?: Maybe<ValoreAttributoNode>;
  /** Regola per l'associazione automatica dei prodotti a questa categoria */
  regolaAssociazioneMaster?: Maybe<CategoriaRegolaAssociazioneMaster>;
  ordine: Scalars['Int'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  boxTitolo?: Maybe<Scalars['String']>;
  boxTitoloIt?: Maybe<Scalars['String']>;
  boxTitoloEn?: Maybe<Scalars['String']>;
  boxSottotitolo?: Maybe<Scalars['String']>;
  boxSottotitoloIt?: Maybe<Scalars['String']>;
  boxSottotitoloEn?: Maybe<Scalars['String']>;
  boxTesto?: Maybe<Scalars['String']>;
  boxTestoIt?: Maybe<Scalars['String']>;
  boxTestoEn?: Maybe<Scalars['String']>;
  /** Categoria visualizzabile nel menu principale */
  inMenu: Scalars['Boolean'];
  rootMenuSecondario?: Maybe<CategoriaRootMenuSecondario>;
  googleProductCategory?: Maybe<Scalars['Int']>;
  amazonSellerCategory?: Maybe<Scalars['Int']>;
  /** Se flaggato la categoria non apparirà in sitemap e nelle briciole */
  seoDisabled: Scalars['Boolean'];
  lft: Scalars['Int'];
  rght: Scalars['Int'];
  treeId: Scalars['Int'];
  level: Scalars['Int'];
  children: CategoriaNodeConnection;
  pk?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  contentTypeId?: Maybe<Scalars['Int']>;
  slugTree?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  breadcrumbs?: Maybe<Array<Maybe<BreadcrumbItemType>>>;
  sottoCategorie?: Maybe<Array<Maybe<CategoriaNode>>>;
  cantina?: Maybe<CantineNode>;
  adminUrl?: Maybe<Scalars['String']>;
  modelClass?: Maybe<Scalars['String']>;
  pagina?: Maybe<PaginaNode>;
};


export type CategoriaNodeChildrenArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CategoriaNodeConnection = {
  __typename?: 'CategoriaNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoriaNodeEdge>>;
};

/** A Relay edge containing a `CategoriaNode` and its cursor. */
export type CategoriaNodeEdge = {
  __typename?: 'CategoriaNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CategoriaNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum CategoriaRegolaAssociazioneMaster {
  /** In promozione (non flash) */
  Promotion = 'PROMOTION',
  /** In promozione flash */
  FlashPromotion = 'FLASH_PROMOTION'
}

/** An enumeration. */
export enum CategoriaRootMenuSecondario {
  /** Il vino giusto per */
  VinoGiusto = 'VINO_GIUSTO'
}

export type CheckoutType = {
  __typename?: 'CheckoutType';
  sistemaPagamento?: Maybe<SistemaPagamentoType>;
  sistemaSpedizione?: Maybe<SistemaSpedizioneType>;
  indirizzoSpedizione?: Maybe<IndirizzoNode>;
  indirizzoFatturazione?: Maybe<IndirizzoNode>;
  richiediFattura?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<RigaCheckoutNode>>>;
  totaleSenzaPromozioni?: Maybe<Scalars['Float']>;
  prezzo?: Maybe<Scalars['Float']>;
  scontoAssoluto?: Maybe<Scalars['Float']>;
  scontoPercentuale?: Maybe<Scalars['Float']>;
  totaleScontato?: Maybe<Scalars['Float']>;
  totaleScontatoNetto?: Maybe<Scalars['Float']>;
  totaleProdottiScontato?: Maybe<Scalars['Float']>;
  promoApplicata?: Maybe<PromozioneType>;
  sistemiPagamento?: Maybe<Array<Maybe<SistemaPagamentoType>>>;
  sistemiSpedizione?: Maybe<Array<Maybe<SistemaSpedizioneType>>>;
  includiMessaggio?: Maybe<Scalars['Boolean']>;
  messaggio?: Maybe<Scalars['String']>;
  messaggioFirma?: Maybe<Scalars['String']>;
  accise?: Maybe<Scalars['Float']>;
  iva?: Maybe<Scalars['Float']>;
  spedizioneEstero?: Maybe<Scalars['Float']>;
  erroreApisEstero?: Maybe<Scalars['Boolean']>;
};

export type ConfirmUtente = {
  __typename?: 'ConfirmUtente';
  status?: Maybe<Scalars['Boolean']>;
};

export type ConfirmUtenteNewsletter = {
  __typename?: 'ConfirmUtenteNewsletter';
  status?: Maybe<Scalars['Boolean']>;
};

export type ConfirmUtenteNewsletterGiv = {
  __typename?: 'ConfirmUtenteNewsletterGiv';
  status?: Maybe<Scalars['Boolean']>;
};

export type CreateGivUtenteContatto = {
  __typename?: 'CreateGivUtenteContatto';
  status?: Maybe<Scalars['Boolean']>;
};

export type CreateIndirizzo = {
  __typename?: 'CreateIndirizzo';
  indirizzo?: Maybe<IndirizzoNode>;
};

export type CreateUtente = {
  __typename?: 'CreateUtente';
  utente?: Maybe<UtenteNode>;
  status?: Maybe<Scalars['Boolean']>;
};

export type CreateUtenteContatto = {
  __typename?: 'CreateUtenteContatto';
  status?: Maybe<Scalars['Boolean']>;
};

export type CreateUtenteNewsletter = {
  __typename?: 'CreateUtenteNewsletter';
  status?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type CreateUtenteNewsletterGiv = {
  __typename?: 'CreateUtenteNewsletterGiv';
  status?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};



export type DeleteIndirizzo = {
  __typename?: 'DeleteIndirizzo';
  message?: Maybe<Scalars['String']>;
};

export type DeleteUtente = {
  __typename?: 'DeleteUtente';
  message?: Maybe<Scalars['String']>;
};

export type DescrizioneAggiuntivaProdottoType = {
  __typename?: 'DescrizioneAggiuntivaProdottoType';
  id: Scalars['ID'];
  testo?: Maybe<Scalars['String']>;
  testoIt?: Maybe<Scalars['String']>;
  testoEn?: Maybe<Scalars['String']>;
  tipoDescrizioneAggiuntiva: TipoDescrizioneAggiuntivaProdottoType;
  prodotto: ProdottoNode;
  chiave?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  titolo?: Maybe<Scalars['String']>;
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename?: 'DjangoDebug';
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<DjangoDebugSql>>>;
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  __typename?: 'DjangoDebugSQL';
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String'];
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String'];
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']>;
  /** Duration of this database query in seconds. */
  duration: Scalars['Float'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String'];
  /** JSON encoded database query parameters. */
  params: Scalars['String'];
  /** Start time of this database query. */
  startTime: Scalars['Float'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean'];
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean'];
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']>;
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']>;
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']>;
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']>;
};

export type EventoNode = Node & {
  __typename?: 'EventoNode';
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionIt?: Maybe<Scalars['String']>;
  seoDescriptionEn?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleIt?: Maybe<Scalars['String']>;
  seoTitleEn?: Maybe<Scalars['String']>;
  seoH1?: Maybe<Scalars['String']>;
  titolo: Scalars['String'];
  titoloIt?: Maybe<Scalars['String']>;
  titoloEn?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  statoPubblicazione: PaginaStatoPubblicazione;
  /** Data e ora da quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaDal?: Maybe<Scalars['DateTime']>;
  /** Data e ora fino a quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaAl?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  slugIt?: Maybe<Scalars['String']>;
  slugEn?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  intro: Scalars['String'];
  introIt?: Maybe<Scalars['String']>;
  introEn?: Maybe<Scalars['String']>;
  nome: Scalars['String'];
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  /** Durata in minuti */
  durata: Scalars['Int'];
  /** Prezzo */
  prezzo: Scalars['Float'];
  categoria?: Maybe<CategoriaEventoNode>;
  pk?: Maybe<Scalars['Int']>;
};

export type FaqNode = Node & {
  __typename?: 'FaqNode';
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionIt?: Maybe<Scalars['String']>;
  seoDescriptionEn?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleIt?: Maybe<Scalars['String']>;
  seoTitleEn?: Maybe<Scalars['String']>;
  seoH1?: Maybe<Scalars['String']>;
  seoH1It?: Maybe<Scalars['String']>;
  seoH1En?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  alias: Scalars['String'];
  nome?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  voceMenu?: Maybe<Scalars['String']>;
  voceMenuIt?: Maybe<Scalars['String']>;
  voceMenuEn?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  slugIt?: Maybe<Scalars['String']>;
  slugEn?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  categoria: CategoriaFaqNode;
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  pk?: Maybe<Scalars['Int']>;
};

export type FaqNodeConnection = {
  __typename?: 'FaqNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FaqNodeEdge>>;
};

/** A Relay edge containing a `FaqNode` and its cursor. */
export type FaqNodeEdge = {
  __typename?: 'FaqNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<FaqNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};


export type GivUtenteContattoInput = {
  nome: Scalars['String'];
  cognome: Scalars['String'];
  email: Scalars['String'];
  telefono?: Maybe<Scalars['String']>;
  motivo?: Maybe<Scalars['String']>;
  messaggio: Scalars['String'];
  allegato?: Maybe<Scalars['Upload']>;
  cantina?: Maybe<Scalars['String']>;
  site: Scalars['Int'];
  captcha: Scalars['String'];
};

export type ImageCantineNode = {
  __typename?: 'ImageCantineNode';
  image?: Maybe<Scalars['String']>;
  imageResponsive?: Maybe<Scalars['String']>;
  tipo?: Maybe<TipoMediaNode>;
  ordinamento: Scalars['Int'];
  id: Scalars['ID'];
  cantine: CantineNode;
  pk?: Maybe<Scalars['Int']>;
};

export type ImageNewsNode = {
  __typename?: 'ImageNewsNode';
  image?: Maybe<Scalars['String']>;
  imageResponsive?: Maybe<Scalars['String']>;
  tipo?: Maybe<TipoMediaNode>;
  ordinamento: Scalars['Int'];
  id: Scalars['ID'];
  news: NewsNode;
  pk?: Maybe<Scalars['Int']>;
};

export type ImageSliderType = {
  __typename?: 'ImageSliderType';
  preview?: Maybe<Scalars['String']>;
  main?: Maybe<Scalars['String']>;
};

export type IndirizzoDeleteInput = {
  pk: Scalars['Int'];
};

export type IndirizzoInput = {
  nome: Scalars['String'];
  cognome?: Maybe<Scalars['String']>;
  presso?: Maybe<Scalars['String']>;
  indirizzo: Scalars['String'];
  default?: Maybe<Scalars['Boolean']>;
  telefono?: Maybe<Scalars['String']>;
  citta: Scalars['String'];
  provincia?: Maybe<Scalars['String']>;
  cap: Scalars['String'];
  cf?: Maybe<Scalars['String']>;
  piva?: Maybe<Scalars['String']>;
  codiceDestinatarioUnivoco?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
  privato?: Maybe<Scalars['String']>;
  saveAddress: Scalars['Boolean'];
  isFatturazione: Scalars['Boolean'];
  nazione?: Maybe<Scalars['Int']>;
};

export type IndirizzoNode = Node & {
  __typename?: 'IndirizzoNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  presso?: Maybe<Scalars['String']>;
  indirizzo?: Maybe<Scalars['String']>;
  numeroCivico?: Maybe<Scalars['String']>;
  telefono?: Maybe<Scalars['String']>;
  telefono2?: Maybe<Scalars['String']>;
  citta?: Maybe<Scalars['String']>;
  provincia?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  cf?: Maybe<Scalars['String']>;
  piva?: Maybe<Scalars['String']>;
  codiceDestinatarioUnivoco?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
  privato?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  utente: UtenteNode;
  nazione: NazioneNode;
  default: Scalars['Boolean'];
  cancellato: Scalars['Boolean'];
  nascondi: Scalars['Boolean'];
  isFatturazione: Scalars['Boolean'];
  indirizziOrdini: Array<IndirizzoOrdineNode>;
  pk?: Maybe<Scalars['Int']>;
};

export type IndirizzoNodeConnection = {
  __typename?: 'IndirizzoNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IndirizzoNodeEdge>>;
};

/** A Relay edge containing a `IndirizzoNode` and its cursor. */
export type IndirizzoNodeEdge = {
  __typename?: 'IndirizzoNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<IndirizzoNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type IndirizzoOrdineNode = {
  __typename?: 'IndirizzoOrdineNode';
  title?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  presso?: Maybe<Scalars['String']>;
  indirizzo?: Maybe<Scalars['String']>;
  numeroCivico?: Maybe<Scalars['String']>;
  telefono?: Maybe<Scalars['String']>;
  telefono2?: Maybe<Scalars['String']>;
  citta?: Maybe<Scalars['String']>;
  provincia?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  cf?: Maybe<Scalars['String']>;
  piva?: Maybe<Scalars['String']>;
  codiceDestinatarioUnivoco?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
  privato?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  indirizzoOriginale?: Maybe<IndirizzoNode>;
  nazione?: Maybe<NazioneNode>;
  ordineAsIndirizzoFatturazione?: Maybe<OrdineNode>;
  ordineAsIndirizzoSpedizione?: Maybe<OrdineNode>;
};

export type IndirizzoUpdateInput = {
  pk: Scalars['Int'];
  nome?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  indirizzo?: Maybe<Scalars['String']>;
  telefono?: Maybe<Scalars['String']>;
  citta?: Maybe<Scalars['String']>;
  provincia?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  cf?: Maybe<Scalars['String']>;
  piva?: Maybe<Scalars['String']>;
  codiceDestinatarioUnivoco?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
  privato?: Maybe<Scalars['String']>;
  nazione?: Maybe<Scalars['String']>;
};

export type InfoProdottoNode = {
  __typename?: 'InfoProdottoNode';
  id: Scalars['ID'];
  alias?: Maybe<Scalars['String']>;
  prodotto?: Maybe<ProdottoNode>;
  youtubeId?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  fileTecnico?: Maybe<Scalars['String']>;
  filePremi?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
};

export type LineaProdottoNode = {
  __typename?: 'LineaProdottoNode';
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoH1?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  statoPubblicazione: PaginaStatoPubblicazione;
  /** Data e ora da quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaDal?: Maybe<Scalars['DateTime']>;
  /** Data e ora fino a quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaAl?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  alias?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  titolo?: Maybe<Scalars['String']>;
  prodotti?: Maybe<Array<Maybe<ProdottoNode>>>;
  slug?: Maybe<Scalars['String']>;
  slugIt?: Maybe<Scalars['String']>;
  slugEn?: Maybe<Scalars['String']>;
  cantina?: Maybe<CantineNode>;
  ordinamento: Scalars['Int'];
  pk?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type Logout = {
  __typename?: 'Logout';
  status?: Maybe<Scalars['Boolean']>;
};

export type MenuItemGivNode = Node & {
  __typename?: 'MenuItemGivNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  alias?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  attivo?: Maybe<Scalars['Boolean']>;
  blank?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nameIt?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  stile?: Maybe<Scalars['String']>;
  intro?: Maybe<Scalars['String']>;
  introIt?: Maybe<Scalars['String']>;
  introEn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionIt?: Maybe<Scalars['String']>;
  descriptionEn?: Maybe<Scalars['String']>;
  descriptionHtml?: Maybe<Scalars['String']>;
  descriptionHtmlIt?: Maybe<Scalars['String']>;
  descriptionHtmlEn?: Maybe<Scalars['String']>;
  visibileDal?: Maybe<Scalars['DateTime']>;
  visibileAl?: Maybe<Scalars['DateTime']>;
  textCta: Scalars['String'];
  textCtaIt?: Maybe<Scalars['String']>;
  textCtaEn?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageTablet?: Maybe<Scalars['String']>;
  imageMobile?: Maybe<Scalars['String']>;
  parent?: Maybe<MenuItemNode>;
  url?: Maybe<Scalars['String']>;
  linkedObjectId?: Maybe<Scalars['Int']>;
  lft: Scalars['Int'];
  rght: Scalars['Int'];
  treeId: Scalars['Int'];
  level: Scalars['Int'];
  pk?: Maybe<Scalars['Int']>;
  finalUrl?: Maybe<Scalars['String']>;
  newsCollegata?: Maybe<NewsCollegata>;
  children?: Maybe<Array<Maybe<MenuItemGivNode>>>;
};

export type MenuItemNode = Node & {
  __typename?: 'MenuItemNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  alias?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  attivo?: Maybe<Scalars['Boolean']>;
  blank?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nameIt?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  stile?: Maybe<Scalars['String']>;
  intro?: Maybe<Scalars['String']>;
  introIt?: Maybe<Scalars['String']>;
  introEn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionIt?: Maybe<Scalars['String']>;
  descriptionEn?: Maybe<Scalars['String']>;
  descriptionHtml?: Maybe<Scalars['String']>;
  descriptionHtmlIt?: Maybe<Scalars['String']>;
  descriptionHtmlEn?: Maybe<Scalars['String']>;
  visibileDal?: Maybe<Scalars['DateTime']>;
  visibileAl?: Maybe<Scalars['DateTime']>;
  textCta: Scalars['String'];
  textCtaIt?: Maybe<Scalars['String']>;
  textCtaEn?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageTablet?: Maybe<Scalars['String']>;
  imageMobile?: Maybe<Scalars['String']>;
  parent?: Maybe<MenuItemNode>;
  url?: Maybe<Scalars['String']>;
  linkedObjectId?: Maybe<Scalars['Int']>;
  lft: Scalars['Int'];
  rght: Scalars['Int'];
  treeId: Scalars['Int'];
  level: Scalars['Int'];
  children: MenuItemNodeConnection;
  pagine: PaginaNodeConnection;
  pk?: Maybe<Scalars['Int']>;
  finalUrl?: Maybe<Scalars['String']>;
  newsCollegata?: Maybe<NewsCollegata>;
};


export type MenuItemNodeChildrenArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['ID']>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  attivo?: Maybe<Scalars['Boolean']>;
};


export type MenuItemNodePagineArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  tipologia?: Maybe<Scalars['String']>;
};

export type MenuItemNodeConnection = {
  __typename?: 'MenuItemNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MenuItemNodeEdge>>;
};

/** A Relay edge containing a `MenuItemNode` and its cursor. */
export type MenuItemNodeEdge = {
  __typename?: 'MenuItemNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<MenuItemNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type MotivazioniContattoNode = Node & {
  __typename?: 'MotivazioniContattoNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  alias: Scalars['String'];
  chiave?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  ordine: Scalars['Int'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  pk?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUtenteNewsletter?: Maybe<CreateUtenteNewsletter>;
  confirmUtenteNewsletter?: Maybe<ConfirmUtenteNewsletter>;
  createUtenteNewsletterGiv?: Maybe<CreateUtenteNewsletterGiv>;
  confirmUtenteNewsletterGiv?: Maybe<ConfirmUtenteNewsletterGiv>;
  createUtenteContatto?: Maybe<CreateUtenteContatto>;
  createGivUtenteContatto?: Maybe<CreateGivUtenteContatto>;
  setPromoCode?: Maybe<SetPromoCode>;
  removePromoCode?: Maybe<RemovePromoCode>;
  addItem?: Maybe<AddItem>;
  addMultipleItems?: Maybe<AddMultipleItems>;
  removeItem?: Maybe<RemoveItem>;
  addItemWishlist?: Maybe<AddItemWishlist>;
  removeItemWishlist?: Maybe<RemoveItemWishlist>;
  setMessaggio?: Maybe<SetMessaggio>;
  setIndirizzi?: Maybe<SetIndirizzi>;
  setSpedizione?: Maybe<SetSpedizione>;
  setPagamento?: Maybe<SetPagamento>;
  setCheckout?: Maybe<SetCheckout>;
  createUtente?: Maybe<CreateUtente>;
  updateUtente?: Maybe<UpdateUtente>;
  updatePassword?: Maybe<UpdatePassword>;
  deleteUtente?: Maybe<DeleteUtente>;
  confirmUtente?: Maybe<ConfirmUtente>;
  createIndirizzo?: Maybe<CreateIndirizzo>;
  updateIndirizzo?: Maybe<UpdateIndirizzo>;
  deleteIndirizzo?: Maybe<DeleteIndirizzo>;
  recuperaPassword?: Maybe<RecuperaPassword>;
  resetPassword?: Maybe<ResetPassword>;
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  verifyToken?: Maybe<Verify>;
  logout?: Maybe<Logout>;
  /** Social Auth for JSON Web Token (JWT) with token_issued signal */
  socialAuth?: Maybe<SocialAuthSignalJwt>;
  refreshToken?: Maybe<Refresh>;
  revokeToken?: Maybe<Revoke>;
};


export type MutationCreateUtenteNewsletterArgs = {
  input: RichiestaIscrizioneNewsletterInput;
};


export type MutationConfirmUtenteNewsletterArgs = {
  token: Scalars['String'];
};


export type MutationCreateUtenteNewsletterGivArgs = {
  input: RichiestaIscrizioneNewsLetterInputGiv;
};


export type MutationConfirmUtenteNewsletterGivArgs = {
  token: Scalars['String'];
};


export type MutationCreateUtenteContattoArgs = {
  input: UtenteContattoInput;
};


export type MutationCreateGivUtenteContattoArgs = {
  input: GivUtenteContattoInput;
};


export type MutationSetPromoCodeArgs = {
  promoCode?: Maybe<Scalars['String']>;
};


export type MutationRemovePromoCodeArgs = {
  promoCode?: Maybe<Scalars['String']>;
};


export type MutationAddItemArgs = {
  item?: Maybe<Scalars['Int']>;
  quantita?: Maybe<Scalars['Int']>;
};


export type MutationAddMultipleItemsArgs = {
  items?: Maybe<Array<Maybe<AddItemInput>>>;
};


export type MutationRemoveItemArgs = {
  estero?: Maybe<Scalars['Boolean']>;
  item?: Maybe<Scalars['Int']>;
};


export type MutationAddItemWishlistArgs = {
  item?: Maybe<Scalars['Int']>;
  quantita?: Maybe<Scalars['Int']>;
};


export type MutationRemoveItemWishlistArgs = {
  item?: Maybe<Scalars['Int']>;
};


export type MutationSetMessaggioArgs = {
  includiMessaggio?: Maybe<Scalars['Boolean']>;
  messaggio?: Maybe<Scalars['String']>;
  messaggioFirma?: Maybe<Scalars['String']>;
};


export type MutationSetIndirizziArgs = {
  indirizzoFatturazione?: Maybe<Scalars['Int']>;
  indirizzoSpedizione?: Maybe<Scalars['Int']>;
  richiediFattura?: Maybe<Scalars['Boolean']>;
};


export type MutationSetSpedizioneArgs = {
  sistemaSpedizione?: Maybe<Scalars['Int']>;
};


export type MutationSetPagamentoArgs = {
  sistemaPagamento?: Maybe<Scalars['Int']>;
};


export type MutationSetCheckoutArgs = {
  sistemaPagamento?: Maybe<Scalars['Int']>;
};


export type MutationCreateUtenteArgs = {
  input: UtenteInput;
};


export type MutationUpdateUtenteArgs = {
  input: UtenteUpdateInput;
};


export type MutationUpdatePasswordArgs = {
  input: PasswordUpdateInput;
};


export type MutationConfirmUtenteArgs = {
  token: Scalars['String'];
};


export type MutationCreateIndirizzoArgs = {
  input: IndirizzoInput;
};


export type MutationUpdateIndirizzoArgs = {
  input: IndirizzoUpdateInput;
};


export type MutationDeleteIndirizzoArgs = {
  input: IndirizzoDeleteInput;
};


export type MutationRecuperaPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationTokenAuthArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


export type MutationVerifyTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationSocialAuthArgs = {
  accessToken: Scalars['String'];
  provider: Scalars['String'];
};


export type MutationRefreshTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationRevokeTokenArgs = {
  refreshToken?: Maybe<Scalars['String']>;
};

export type NazioneNode = Node & {
  __typename?: 'NazioneNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  alias: Scalars['String'];
  nome?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  isoCode: Scalars['String'];
  attiva: Scalars['Boolean'];
  iva?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  indirizzi: IndirizzoNodeConnection;
  indirizziOrdini: Array<IndirizzoOrdineNode>;
  pk?: Maybe<Scalars['Int']>;
};


export type NazioneNodeIndirizziArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  isFatturazione?: Maybe<Scalars['Boolean']>;
};

export type NazioneNodeConnection = {
  __typename?: 'NazioneNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NazioneNodeEdge>>;
};

/** A Relay edge containing a `NazioneNode` and its cursor. */
export type NazioneNodeEdge = {
  __typename?: 'NazioneNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<NazioneNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type NewsCollegata = {
  __typename?: 'NewsCollegata';
  pk?: Maybe<Scalars['Int']>;
  titolo?: Maybe<Scalars['String']>;
  sottotitolo?: Maybe<Scalars['String']>;
  listingImage?: Maybe<Scalars['String']>;
  categoria?: Maybe<CategoriaNewsNode>;
  url?: Maybe<Scalars['String']>;
};

export type NewsNode = Node & {
  __typename?: 'NewsNode';
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionIt?: Maybe<Scalars['String']>;
  seoDescriptionEn?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleIt?: Maybe<Scalars['String']>;
  seoTitleEn?: Maybe<Scalars['String']>;
  seoH1?: Maybe<Scalars['String']>;
  seoH1It?: Maybe<Scalars['String']>;
  seoH1En?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  alias: Scalars['String'];
  titolo?: Maybe<Scalars['String']>;
  titoloIt?: Maybe<Scalars['String']>;
  titoloEn?: Maybe<Scalars['String']>;
  titoloListing?: Maybe<Scalars['String']>;
  titoloListingIt?: Maybe<Scalars['String']>;
  titoloListingEn?: Maybe<Scalars['String']>;
  sottotitolo?: Maybe<Scalars['String']>;
  sottotitoloIt?: Maybe<Scalars['String']>;
  sottotitoloEn?: Maybe<Scalars['String']>;
  sottotitoloListing?: Maybe<Scalars['String']>;
  sottotitoloListingIt?: Maybe<Scalars['String']>;
  sottotitoloListingEn?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  slugIt?: Maybe<Scalars['String']>;
  slugEn?: Maybe<Scalars['String']>;
  messaggio?: Maybe<Scalars['String']>;
  messaggioIt?: Maybe<Scalars['String']>;
  messaggioEn?: Maybe<Scalars['String']>;
  testo?: Maybe<Scalars['String']>;
  testoIt?: Maybe<Scalars['String']>;
  testoEn?: Maybe<Scalars['String']>;
  inListing: Scalars['Boolean'];
  data: Scalars['Date'];
  dataOraPubblicazione: Scalars['DateTime'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  categoria?: Maybe<CategoriaNewsNode>;
  stato?: Maybe<StatoPaginaNode>;
  inEvidenza: Scalars['Boolean'];
  valoriAttributo: ValoreAttributoNodeConnection;
  prodottiCorrelati?: Maybe<Array<Maybe<ProdottoNode>>>;
  xProdottiNews: Array<ProdottoNewsNode>;
  immagini: Array<ImageNewsNode>;
  video: Array<VideoNewsNode>;
  pk?: Maybe<Scalars['Int']>;
  contentTypeId?: Maybe<Scalars['Int']>;
  mainImage?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  listingImage?: Maybe<Scalars['String']>;
  newsCorrelate?: Maybe<Array<Maybe<NewsNode>>>;
  breadcrumbs?: Maybe<Array<Maybe<BreadcrumbItemType>>>;
  adminUrl?: Maybe<Scalars['String']>;
  modelClass?: Maybe<Scalars['String']>;
  pagina?: Maybe<PaginaNode>;
};


export type NewsNodeValoriAttributoArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type NewsNodeConnection = {
  __typename?: 'NewsNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NewsNodeEdge>>;
};

/** A Relay edge containing a `NewsNode` and its cursor. */
export type NewsNodeEdge = {
  __typename?: 'NewsNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<NewsNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  user?: Maybe<UtenteNode>;
  token: Scalars['String'];
};

/** An enumeration. */
export enum OmaggioLogicaAttivazione {
  /** Almeno un prodotto */
  A_1 = 'A_1',
  /** Solo per importo minimo */
  A_3 = 'A_3'
}

export type OmaggioType = {
  __typename?: 'OmaggioType';
  id: Scalars['ID'];
  dal: Scalars['DateTime'];
  al: Scalars['DateTime'];
  attiva: Scalars['Boolean'];
  prodottoOmaggio: VarianteProdottoNode;
  prodotti: VarianteProdottoNodeConnection;
  logicaAttivazione: OmaggioLogicaAttivazione;
  nome: Scalars['String'];
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  /** Lasciare a 0 per disabilitare l'importo minimo o il numero minimo di prodotti */
  importoMinimo?: Maybe<Scalars['Float']>;
  omaggioVirtuale: Scalars['Boolean'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
};


export type OmaggioTypeProdottiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrdineNode = Node & {
  __typename?: 'OrdineNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  crittografato: Scalars['Boolean'];
  codice?: Maybe<Scalars['String']>;
  utente?: Maybe<UtenteNode>;
  stato?: Maybe<StatoNode>;
  nome: Scalars['String'];
  cognome?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  cf?: Maybe<Scalars['String']>;
  piva?: Maybe<Scalars['String']>;
  codiceDestinatarioUnivoco?: Maybe<Scalars['String']>;
  privato: Scalars['Boolean'];
  paymentId?: Maybe<Scalars['String']>;
  securitytoken?: Maybe<Scalars['String']>;
  indirizzoFatturazione?: Maybe<IndirizzoOrdineNode>;
  indirizzoSpedizione?: Maybe<IndirizzoOrdineNode>;
  sistemaSpedizione?: Maybe<SistemaSpedizioneType>;
  sistemaPagamento?: Maybe<SistemaPagamentoType>;
  prezzoTotale: Scalars['Float'];
  prezzoTotaleScontato: Scalars['Float'];
  promozione?: Maybe<PromozioneType>;
  promozioneValorePercentuale: Scalars['Float'];
  promozioneValoreAssoluto: Scalars['Float'];
  giacenzaScalata: Scalars['Boolean'];
  includiMessaggio: Scalars['Boolean'];
  messaggio?: Maybe<Scalars['String']>;
  messaggioFirma?: Maybe<Scalars['String']>;
  telefono?: Maybe<Scalars['String']>;
  fatturaRichiesta: Scalars['Boolean'];
  noteConsegna?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  numeroOdv?: Maybe<Scalars['String']>;
  numeroFattura?: Maybe<Scalars['String']>;
  dataFattura?: Maybe<Scalars['Date']>;
  overrideTestoTotaleFattura?: Maybe<Scalars['String']>;
  numeroRicevuta?: Maybe<Scalars['String']>;
  dataRicevuta?: Maybe<Scalars['Date']>;
  notificatoAmazon: Scalars['Boolean'];
  syncedWithConnectif: Scalars['Boolean'];
  cardType?: Maybe<Scalars['String']>;
  ipUtente?: Maybe<Scalars['String']>;
  codiceCampagnaMailchimp?: Maybe<Scalars['String']>;
  iva?: Maybe<Scalars['Float']>;
  ivaPercentuale?: Maybe<Scalars['Float']>;
  accise?: Maybe<Scalars['Float']>;
  spedizioneEstero?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  righeOrdine?: Maybe<Array<Maybe<RigaOrdineNode>>>;
  statiOrdine: StatoOrdineNodeConnection;
  spedizioniOrdine: SpedizioneOrdineNodeConnection;
  pk?: Maybe<Scalars['Int']>;
  data?: Maybe<Scalars['String']>;
  spedizioneOrdine?: Maybe<SpedizioneOrdineNode>;
  hasFattura?: Maybe<Scalars['Boolean']>;
  hasRicevuta?: Maybe<Scalars['Boolean']>;
  deltaGiorniAcquisto?: Maybe<Scalars['Int']>;
  numeroProdotti?: Maybe<Scalars['Int']>;
  estero?: Maybe<Scalars['Boolean']>;
};


export type OrdineNodeStatiOrdineArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrdineNodeSpedizioniOrdineArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrdineNodeConnection = {
  __typename?: 'OrdineNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrdineNodeEdge>>;
};

/** A Relay edge containing a `OrdineNode` and its cursor. */
export type OrdineNodeEdge = {
  __typename?: 'OrdineNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<OrdineNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type PaginaNode = Node & {
  __typename?: 'PaginaNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionIt?: Maybe<Scalars['String']>;
  seoDescriptionEn?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleIt?: Maybe<Scalars['String']>;
  seoTitleEn?: Maybe<Scalars['String']>;
  seoH1?: Maybe<Scalars['String']>;
  titolo: Scalars['String'];
  titoloIt?: Maybe<Scalars['String']>;
  titoloEn?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  statoPubblicazione: PaginaStatoPubblicazione;
  /** Data e ora da quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaDal?: Maybe<Scalars['DateTime']>;
  /** Data e ora fino a quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaAl?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  slugIt?: Maybe<Scalars['String']>;
  slugEn?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  tipologia: PaginaTipologia;
  template: PaginaTemplate;
  parent?: Maybe<PaginaNode>;
  chiave?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  pathIt?: Maybe<Scalars['String']>;
  pathEn?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  sottoTitolo?: Maybe<Scalars['String']>;
  sottoTitoloIt?: Maybe<Scalars['String']>;
  sottoTitoloEn?: Maybe<Scalars['String']>;
  inRicerca: Scalars['Boolean'];
  inSitemap: Scalars['Boolean'];
  linkEsterno?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  menu?: Maybe<MenuItemGivNode>;
  lft: Scalars['Int'];
  rght: Scalars['Int'];
  treeId: Scalars['Int'];
  level: Scalars['Int'];
  children?: Maybe<Array<Maybe<PaginaNode>>>;
  pk?: Maybe<Scalars['Int']>;
  breadcrumbs?: Maybe<Array<Maybe<BreadcrumbType>>>;
  seo?: Maybe<SeoType>;
  adminUrl?: Maybe<Scalars['String']>;
  contentTypeId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PaginaNodeConnection = {
  __typename?: 'PaginaNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaginaNodeEdge>>;
};

/** A Relay edge containing a `PaginaNode` and its cursor. */
export type PaginaNodeEdge = {
  __typename?: 'PaginaNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PaginaNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum PaginaStatoPubblicazione {
  /** Pubblicato */
  Pubblicato = 'PUBBLICATO',
  /** Non Pubblicato */
  Nopubblicato = 'NOPUBBLICATO'
}

/** An enumeration. */
export enum PaginaTemplate {
  /** Default */
  Default = 'DEFAULT'
}

/** An enumeration. */
export enum PaginaTipologia {
  /** Pagina */
  Page = 'PAGE',
  /** Pagina applicazione */
  PageApp = 'PAGE_APP',
  /** Pagine Entità */
  PageAppMany = 'PAGE_APP_MANY'
}

export type PasswordUpdateInput = {
  oldPassword?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
};

export type PremioType = {
  __typename?: 'PremioType';
  id: Scalars['ID'];
  alias: Scalars['String'];
  nome: Scalars['String'];
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  chiave: Scalars['String'];
  sigla?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  stile?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  voti: Array<VotoType>;
};

export type PrezzoProdottoNode = {
  __typename?: 'PrezzoProdottoNode';
  pk?: Maybe<Scalars['Int']>;
  prezzo?: Maybe<Scalars['Float']>;
  prezzoOmnibus?: Maybe<Scalars['Float']>;
  prezzoScontato?: Maybe<Scalars['Float']>;
  promoApplicataNome?: Maybe<Scalars['String']>;
  promoApplicata?: Maybe<PromozioneType>;
  scontoPercentuale?: Maybe<Scalars['Int']>;
  disponibilita?: Maybe<Scalars['Int']>;
  quantitaCarrello?: Maybe<Scalars['Int']>;
  inWishlist?: Maybe<Scalars['Boolean']>;
};

export type ProdottoBundleNode = {
  __typename?: 'ProdottoBundleNode';
  id: Scalars['ID'];
  prodotto: ProdottoNode;
  variante: VarianteProdottoNode;
  quantita: Scalars['Int'];
};

export type ProdottoNewsNode = {
  __typename?: 'ProdottoNewsNode';
  id: Scalars['ID'];
  prodotto: ProdottoNode;
  news: NewsNode;
  ordine: Scalars['Int'];
};

export type ProdottoNode = Node & {
  __typename?: 'ProdottoNode';
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionIt?: Maybe<Scalars['String']>;
  seoDescriptionEn?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleIt?: Maybe<Scalars['String']>;
  seoTitleEn?: Maybe<Scalars['String']>;
  seoH1?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  seoH1It?: Maybe<Scalars['String']>;
  seoH1En?: Maybe<Scalars['String']>;
  alias: Scalars['String'];
  codice: Scalars['String'];
  nome: Scalars['String'];
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  slugIt?: Maybe<Scalars['String']>;
  slugEn?: Maybe<Scalars['String']>;
  mondo?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  defaultMaster: Scalars['Boolean'];
  descrizione?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  stato?: Maybe<StatoProdottoNode>;
  associati: ProdottoNodeConnection;
  ordinamento: Scalars['Int'];
  bundle: Scalars['Boolean'];
  /** Visualizza nei listing e in scheda prodotto quando manca alla scadenza della promozione. */
  mostraCounter: Scalars['Boolean'];
  variantiBundle: VarianteProdottoNodeConnection;
  linkVideo?: Maybe<Scalars['String']>;
  visibileDal?: Maybe<Scalars['DateTime']>;
  visibileAl?: Maybe<Scalars['DateTime']>;
  vendibileEstero?: Maybe<Scalars['Boolean']>;
  /** In vendita su Amazon. */
  amazon: Scalars['Boolean'];
  /** Visibile su siti GIV */
  giv: Scalars['Boolean'];
  /** Visibile su sito Vinicum */
  vinicum: Scalars['Boolean'];
  promozioni: Array<PromozioneType>;
  promozioniNegative: Array<PromozioneType>;
  prodottiAssociati: ProdottoNodeConnection;
  varianti: VarianteProdottoNodeConnection;
  prodottiBundle?: Maybe<Array<Maybe<ProdottoBundleNode>>>;
  descrizioniAggiuntive: Array<DescrizioneAggiuntivaProdottoType>;
  linee: Array<LineaProdottoNode>;
  info?: Maybe<InfoProdottoNode>;
  categorie: CategoriaNodeConnection;
  righeWishlist: Array<RigaWishlistNode>;
  news: NewsNodeConnection;
  xProdottiNews: Array<ProdottoNewsNode>;
  voti?: Maybe<Array<Maybe<VotoType>>>;
  pk?: Maybe<Scalars['Int']>;
  contentTypeId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  thumbnailImage?: Maybe<Scalars['String']>;
  nomeFantasia?: Maybe<Scalars['String']>;
  fantasia?: Maybe<Scalars['String']>;
  annata?: Maybe<Scalars['String']>;
  denominazione?: Maybe<Scalars['String']>;
  regione?: Maybe<Scalars['String']>;
  vitigno?: Maybe<Scalars['String']>;
  abbinamenti?: Maybe<Scalars['String']>;
  gradazione?: Maybe<Scalars['String']>;
  formato?: Maybe<Scalars['String']>;
  magnum?: Maybe<Scalars['Boolean']>;
  famiglia?: Maybe<Scalars['String']>;
  marca?: Maybe<Scalars['String']>;
  testoFlash?: Maybe<Scalars['String']>;
  cantina?: Maybe<CantineNode>;
  categoria?: Maybe<CategoriaNode>;
  promoApplicataNome?: Maybe<Scalars['String']>;
  promoApplicata?: Maybe<PromozioneType>;
  varianteProdotto?: Maybe<VarianteProdottoNode>;
  correlati?: Maybe<Array<Maybe<ProdottoNode>>>;
  numeroPezziBundle?: Maybe<Scalars['Int']>;
  prezzo?: Maybe<Scalars['Float']>;
  prezzoOmnibus?: Maybe<Scalars['Float']>;
  prezzoScontato?: Maybe<Scalars['Float']>;
  scontoPercentuale?: Maybe<Scalars['Int']>;
  inWishlist?: Maybe<Scalars['Boolean']>;
  disponibilita?: Maybe<Scalars['Int']>;
  attributi?: Maybe<AttributiProdottoType>;
  omaggio?: Maybe<OmaggioType>;
  datiSchedaTecnica?: Maybe<Array<Maybe<DescrizioneAggiuntivaProdottoType>>>;
  breadcrumbs?: Maybe<Array<Maybe<BreadcrumbItemType>>>;
  acquistabile?: Maybe<Scalars['Boolean']>;
  adminUrl?: Maybe<Scalars['String']>;
  modelClass?: Maybe<Scalars['String']>;
  pagina?: Maybe<PaginaNode>;
  imagesSlider?: Maybe<Array<Maybe<ImageSliderType>>>;
  colore?: Maybe<Scalars['String']>;
  tipologia?: Maybe<Scalars['String']>;
  acquistabileGiv?: Maybe<Scalars['Boolean']>;
};


export type ProdottoNodeAssociatiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};


export type ProdottoNodeVariantiBundleArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProdottoNodeProdottiAssociatiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};


export type ProdottoNodeVariantiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProdottoNodeCategorieArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProdottoNodeNewsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  categoriaId_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ProdottoNodeConnection = {
  __typename?: 'ProdottoNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProdottoNodeEdge>>;
};

/** A Relay edge containing a `ProdottoNode` and its cursor. */
export type ProdottoNodeEdge = {
  __typename?: 'ProdottoNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ProdottoNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ProdottoRedirectNode = {
  __typename?: 'ProdottoRedirectNode';
  redirect?: Maybe<CategoriaNode>;
  statusCodeRedirect?: Maybe<Scalars['String']>;
  prodotto?: Maybe<ProdottoNode>;
};

/** An enumeration. */
export enum PromozioneContext {
  /** Prodotto */
  Prodotto = 'PRODOTTO',
  /** SistemaSpedizione */
  Sistemaspedizione = 'SISTEMASPEDIZIONE',
  /** Checkout */
  Checkout = 'CHECKOUT'
}

/** An enumeration. */
export enum PromozioneRegolaAssociazioneUtente {
  /** Associa all'utente in fase di registrazione */
  Registrazione = 'REGISTRAZIONE',
  /** Associa all'utente che si registra sia alla newsletter che al sito */
  Newsletter = 'NEWSLETTER'
}

export type PromozioneType = {
  __typename?: 'PromozioneType';
  id: Scalars['ID'];
  alias: Scalars['String'];
  descrizione: Scalars['String'];
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  codice: Scalars['String'];
  /** Per le promo valide solo per gli utenti associati definisce quando create automaticamente l'associazone con l'utente. */
  regolaAssociazioneUtente?: Maybe<PromozioneRegolaAssociazioneUtente>;
  dal: Scalars['Date'];
  al: Scalars['Date'];
  attiva: Scalars['Boolean'];
  /** Visualizza nei listing e in scheda prodotto quando manca alla scadenza della promozione. */
  mostraCounter: Scalars['Boolean'];
  /** La promo sarà utilizzabile solo da loggati */
  soloLoggati: Scalars['Boolean'];
  /** Per attivare la promo è necessario inserire il codice in fase di checkout. */
  conCodice: Scalars['Boolean'];
  percentuale: Scalars['Boolean'];
  valore: Scalars['Float'];
  context: PromozioneContext;
  tuttiGliUtenti: Scalars['Boolean'];
  utenti: UtenteNodeConnection;
  tuttiIProdotti: Scalars['Boolean'];
  prodotti: ProdottoNodeConnection;
  prodottiNegativi: ProdottoNodeConnection;
  tuttiICorrieri: Scalars['Boolean'];
  corrieri: Array<SistemaSpedizioneType>;
  /** Spesa minima necessaria per attivare la promozione. */
  limiteSpesa: Scalars['Float'];
  /** Numero di prodotti a carrello necessari per attivare la promo. */
  limiteNumeroProdotti: Scalars['Int'];
  /** Numero di volte che può essere usata la promo dal singolo utente. (0 per disattivare) */
  limiteNumeroUtilizzi: Scalars['Int'];
  /** Numero di volte che è stata utilizzata questa promo. In creazione lasciare a 0. */
  numeroUtilizzi: Scalars['Int'];
  descrizionePopup?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  /** Per attivare la promo è necessario inserire il codice in fase di checkout. */
  conCodiceUnivoco: Scalars['Boolean'];
  /** Promozione attualmente valida per la sincronizzazione con MailChimp */
  promoMailchimp: Scalars['Boolean'];
  ordini: OrdineNodeConnection;
  righeOrdine: RigaOrdineNodeConnection;
  timeUntil?: Maybe<Scalars['String']>;
};


export type PromozioneTypeUtentiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


export type PromozioneTypeProdottiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};


export type PromozioneTypeProdottiNegativiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};


export type PromozioneTypeOrdiniArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


export type PromozioneTypeRigheOrdineArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  visite?: Maybe<Array<Maybe<VisitaNode>>>;
  visita?: Maybe<VisitaNode>;
  visiteGiv?: Maybe<Array<Maybe<VisitaGivNode>>>;
  eventi?: Maybe<Array<Maybe<EventoNode>>>;
  evento?: Maybe<EventoNode>;
  categorieEventi?: Maybe<Array<Maybe<EventoNode>>>;
  cantina?: Maybe<CantineNode>;
  cantine?: Maybe<CantineNodeConnection>;
  _debug?: Maybe<DjangoDebug>;
  motivazioniContatto?: Maybe<Array<Maybe<MotivazioniContattoNode>>>;
  sendersByPage?: Maybe<Array<Maybe<SenderEmailGivType>>>;
  sistemaPagamento?: Maybe<SistemaPagamentoType>;
  sistemaSpedizione?: Maybe<SistemaSpedizioneType>;
  sistemiSpedizioneSenzaIndirizzo?: Maybe<Array<Maybe<SistemaSpedizioneType>>>;
  promozione?: Maybe<PromozioneType>;
  omaggio?: Maybe<OmaggioType>;
  ordine?: Maybe<OrdineNode>;
  ordini?: Maybe<OrdineNodeConnection>;
  ordineCheckout?: Maybe<OrdineNode>;
  categoriaNews?: Maybe<CategoriaNewsNode>;
  categorieNews?: Maybe<CategoriaNewsNodeConnection>;
  news?: Maybe<NewsNode>;
  allNews?: Maybe<NewsNodeConnection>;
  cart?: Maybe<CarrelloType>;
  checkout?: Maybe<CheckoutType>;
  wishlist?: Maybe<WishlistType>;
  categorieFaq?: Maybe<CategoriaFaqNodeConnection>;
  faq?: Maybe<FaqNodeConnection>;
  prodotto?: Maybe<ProdottoRedirectNode>;
  prodotti?: Maybe<ProdottoNodeConnection>;
  prezziProdotti?: Maybe<Array<Maybe<PrezzoProdottoNode>>>;
  correlatiCategoria?: Maybe<Array<Maybe<ProdottoNode>>>;
  setProdotti?: Maybe<Array<Maybe<ProdottoNode>>>;
  lineeProdotti?: Maybe<Array<Maybe<LineaProdottoNode>>>;
  lineaProdotto?: Maybe<LineaProdottoNode>;
  prodottoGiv?: Maybe<ProdottoNode>;
  categoria?: Maybe<CategoriaNode>;
  categorie?: Maybe<CategoriaNodeConnection>;
  pagina?: Maybe<PaginaNode>;
  pagine?: Maybe<PaginaNodeConnection>;
  menuitem?: Maybe<MenuItemNode>;
  menuitems?: Maybe<MenuItemNodeConnection>;
  menuitemsgiv?: Maybe<Array<Maybe<MenuItemGivNode>>>;
  me?: Maybe<UtenteNode>;
  indirizzi?: Maybe<IndirizzoNodeConnection>;
  nazioni?: Maybe<NazioneNodeConnection>;
};


export type QueryVisiteArgs = {
  site: Scalars['Int'];
};


export type QueryVisitaArgs = {
  site: Scalars['Int'];
  slug: Scalars['String'];
};


export type QueryVisiteGivArgs = {
  slug?: Maybe<Scalars['String']>;
};


export type QueryEventiArgs = {
  site: Scalars['Int'];
};


export type QueryEventoArgs = {
  site: Scalars['Int'];
  slug: Scalars['String'];
};


export type QueryCategorieEventiArgs = {
  site: Scalars['Int'];
};


export type QueryCantinaArgs = {
  slug: Scalars['String'];
  giv?: Maybe<Scalars['Boolean']>;
  vinicum?: Maybe<Scalars['Boolean']>;
};


export type QueryCantineArgs = {
  giv?: Maybe<Scalars['Boolean']>;
  vinicum?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySendersByPageArgs = {
  page: Scalars['String'];
};


export type QuerySistemiSpedizioneSenzaIndirizzoArgs = {
  cap?: Maybe<Scalars['String']>;
};


export type QueryOrdineArgs = {
  pk?: Maybe<Scalars['Int']>;
};


export type QueryOrdiniArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


export type QueryCategoriaNewsArgs = {
  slug: Scalars['String'];
};


export type QueryCategorieNewsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryNewsArgs = {
  slug: Scalars['String'];
};


export type QueryAllNewsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  categoriaId_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryCategorieFaqArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryFaqArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryProdottoArgs = {
  slug: Scalars['String'];
};


export type QueryProdottiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryPrezziProdottiArgs = {
  idProdotti?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryCorrelatiCategoriaArgs = {
  chiave: Scalars['String'];
};


export type QuerySetProdottiArgs = {
  chiave: Scalars['String'];
};


export type QueryLineeProdottiArgs = {
  site: Scalars['Int'];
};


export type QueryLineaProdottoArgs = {
  slug: Scalars['String'];
  cantina: Scalars['String'];
  site?: Maybe<Scalars['Int']>;
};


export type QueryProdottoGivArgs = {
  slug: Scalars['String'];
  linea: Scalars['String'];
  cantina: Scalars['String'];
};


export type QueryCategoriaArgs = {
  slugTree: Scalars['String'];
};


export type QueryCategorieArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPaginaArgs = {
  chiave?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  contentTypeId?: Maybe<Scalars['Int']>;
  objectId?: Maybe<Scalars['Int']>;
  siteId?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
};


export type QueryPagineArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  tipologia?: Maybe<Scalars['String']>;
};


export type QueryMenuitemArgs = {
  alias?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};


export type QueryMenuitemsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['ID']>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  attivo?: Maybe<Scalars['Boolean']>;
};


export type QueryMenuitemsgivArgs = {
  siteId?: Maybe<Scalars['Int']>;
};


export type QueryIndirizziArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  isFatturazione?: Maybe<Scalars['Boolean']>;
};


export type QueryNazioniArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type RecuperaPassword = {
  __typename?: 'RecuperaPassword';
  status?: Maybe<Scalars['Boolean']>;
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type RemoveItem = {
  __typename?: 'RemoveItem';
  status?: Maybe<Scalars['Boolean']>;
};

export type RemoveItemWishlist = {
  __typename?: 'RemoveItemWishlist';
  wishlist?: Maybe<WishlistType>;
  status?: Maybe<Scalars['Boolean']>;
};

export type RemovePromoCode = {
  __typename?: 'RemovePromoCode';
  status?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type ResetPassword = {
  __typename?: 'ResetPassword';
  status?: Maybe<Scalars['Boolean']>;
};

export type Revoke = {
  __typename?: 'Revoke';
  revoked: Scalars['Int'];
};

export type RichiestaIscrizioneNewsLetterInputGiv = {
  nome: Scalars['String'];
  cognome: Scalars['String'];
  email: Scalars['String'];
  site: Scalars['Int'];
};

export type RichiestaIscrizioneNewsletterInput = {
  email: Scalars['String'];
  preferenze?: Maybe<Scalars['String']>;
};

export type RigaCarrelloNode = {
  __typename?: 'RigaCarrelloNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  quantita: Scalars['Int'];
  omaggio: Scalars['Boolean'];
  utente?: Maybe<UtenteNode>;
  sessionKey?: Maybe<Scalars['String']>;
  prodotto: VarianteProdottoNode;
  pk?: Maybe<Scalars['Int']>;
};

export type RigaCheckoutNode = {
  __typename?: 'RigaCheckoutNode';
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  quantita: Scalars['Int'];
  omaggio: Scalars['Boolean'];
  id: Scalars['ID'];
  utente: UtenteNode;
  prodotto: VarianteProdottoNode;
  pk?: Maybe<Scalars['Int']>;
};

export type RigaOrdineNode = Node & {
  __typename?: 'RigaOrdineNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  ordine: OrdineNode;
  promozione?: Maybe<PromozioneType>;
  promozioneValorePercentuale: Scalars['Float'];
  promozioneValoreAssoluto: Scalars['Float'];
  tipologia: RigaOrdineTipologia;
  descrizione?: Maybe<Scalars['String']>;
  prodotto?: Maybe<VarianteProdottoNode>;
  codiceProdotto?: Maybe<Scalars['String']>;
  quantitaOrdinata: Scalars['Int'];
  quantitaSpedita?: Maybe<Scalars['Int']>;
  prezzoUnitario: Scalars['Float'];
  prezzoUnitarioScontato: Scalars['Float'];
  prezzoTotale: Scalars['Float'];
  prezzoTotaleScontato: Scalars['Float'];
  paymentId?: Maybe<Scalars['String']>;
  omaggio: Scalars['Boolean'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  pk?: Maybe<Scalars['Int']>;
};

export type RigaOrdineNodeConnection = {
  __typename?: 'RigaOrdineNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RigaOrdineNodeEdge>>;
};

/** A Relay edge containing a `RigaOrdineNode` and its cursor. */
export type RigaOrdineNodeEdge = {
  __typename?: 'RigaOrdineNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<RigaOrdineNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum RigaOrdineTipologia {
  /** prodotto */
  Prodotto = 'PRODOTTO',
  /** spedizione */
  Spedizione = 'SPEDIZIONE',
  /** pagamento */
  Pagamento = 'PAGAMENTO'
}

export type RigaWishlistNode = {
  __typename?: 'RigaWishlistNode';
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  quantita: Scalars['Int'];
  omaggio: Scalars['Boolean'];
  id: Scalars['ID'];
  utente?: Maybe<UtenteNode>;
  sessionKey?: Maybe<Scalars['String']>;
  prodotto: ProdottoNode;
  pk?: Maybe<Scalars['Int']>;
};

export type SenderEmailGivType = {
  __typename?: 'SenderEmailGivType';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
};

export type SeoType = {
  __typename?: 'SeoType';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type SetCheckout = {
  __typename?: 'SetCheckout';
  url?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type SetIndirizzi = {
  __typename?: 'SetIndirizzi';
  status?: Maybe<Scalars['Boolean']>;
};

export type SetMessaggio = {
  __typename?: 'SetMessaggio';
  status?: Maybe<Scalars['Boolean']>;
};

export type SetPagamento = {
  __typename?: 'SetPagamento';
  status?: Maybe<Scalars['Boolean']>;
};

export type SetPromoCode = {
  __typename?: 'SetPromoCode';
  promozione?: Maybe<PromozioneType>;
  status?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type SetSpedizione = {
  __typename?: 'SetSpedizione';
  status?: Maybe<Scalars['Boolean']>;
};

export type SistemaPagamentoType = {
  __typename?: 'SistemaPagamentoType';
  id: Scalars['ID'];
  alias: Scalars['String'];
  nome?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  chiave?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  attivo: Scalars['Boolean'];
  className: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  prezzo: Scalars['Float'];
  /** Soglia massima valida sola per Sistema Pagamento Contrassegno */
  sogliaMassima: Scalars['Float'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  estero: Scalars['Boolean'];
  ordini: OrdineNodeConnection;
  pk?: Maybe<Scalars['Int']>;
};


export type SistemaPagamentoTypeOrdiniArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type SistemaSpedizioneType = {
  __typename?: 'SistemaSpedizioneType';
  id: Scalars['ID'];
  alias: Scalars['String'];
  default: Scalars['Boolean'];
  nome?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  chiave?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  descrizioneEstesa?: Maybe<Scalars['String']>;
  descrizioneEstesaIt?: Maybe<Scalars['String']>;
  descrizioneEstesaEn?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  carrier?: Maybe<Scalars['String']>;
  estero?: Maybe<Scalars['Boolean']>;
  promozioni: Array<PromozioneType>;
  ordini: OrdineNodeConnection;
  pk?: Maybe<Scalars['Int']>;
  prezzoScontato?: Maybe<Scalars['Float']>;
  scontoPercentuale?: Maybe<Scalars['Float']>;
  scontoAssoluto?: Maybe<Scalars['Float']>;
  promoApplicata?: Maybe<PromozioneType>;
  enableForCap?: Maybe<Scalars['Boolean']>;
  enableForCovid?: Maybe<Scalars['Boolean']>;
};


export type SistemaSpedizioneTypeOrdiniArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

/** Social Auth for JSON Web Token (JWT) with token_issued signal */
export type SocialAuthSignalJwt = {
  __typename?: 'SocialAuthSignalJWT';
  social?: Maybe<SocialType>;
  token?: Maybe<Scalars['String']>;
};


export type SocialNode = Node & {
  __typename?: 'SocialNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  user: UtenteNode;
  provider: Scalars['String'];
  uid: Scalars['String'];
  extraData?: Maybe<Scalars['SocialCamelJSON']>;
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
};

export type SocialNodeConnection = {
  __typename?: 'SocialNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SocialNodeEdge>>;
};

/** A Relay edge containing a `SocialNode` and its cursor. */
export type SocialNodeEdge = {
  __typename?: 'SocialNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<SocialNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type SocialType = {
  __typename?: 'SocialType';
  id: Scalars['ID'];
  user: UtenteNode;
  provider: Scalars['String'];
  uid: Scalars['String'];
  extraData?: Maybe<Scalars['SocialCamelJSON']>;
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
};

export type SpedizioneOrdineNode = Node & {
  __typename?: 'SpedizioneOrdineNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  tracking?: Maybe<Scalars['String']>;
  ordine: OrdineNode;
  dataSpedizione?: Maybe<Scalars['Date']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  cancellata: Scalars['Boolean'];
  cost?: Maybe<Scalars['Float']>;
  identificationNumber?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  numeroColli: Scalars['Int'];
  proofOfDelivery?: Maybe<Scalars['String']>;
  numeroColliTipo1: Scalars['Int'];
  numeroColliTipo2: Scalars['Int'];
  numeroColliTipo3: Scalars['Int'];
  numeroColliTipo6: Scalars['Int'];
  pk?: Maybe<Scalars['Int']>;
};

export type SpedizioneOrdineNodeConnection = {
  __typename?: 'SpedizioneOrdineNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SpedizioneOrdineNodeEdge>>;
};

/** A Relay edge containing a `SpedizioneOrdineNode` and its cursor. */
export type SpedizioneOrdineNodeEdge = {
  __typename?: 'SpedizioneOrdineNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<SpedizioneOrdineNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type StatoNode = Node & {
  __typename?: 'StatoNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  alias: Scalars['String'];
  chiave?: Maybe<Scalars['String']>;
  azione?: Maybe<Scalars['String']>;
  className: Scalars['String'];
  nome?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  statiConsentiti: StatoNodeConnection;
  visibileInListing: Scalars['Boolean'];
  colorBackend?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  statoSet: StatoNodeConnection;
  ordini: OrdineNodeConnection;
  statiOrdine: StatoOrdineNodeConnection;
  pk?: Maybe<Scalars['Int']>;
};


export type StatoNodeStatiConsentitiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  chiave?: Maybe<Scalars['String']>;
};


export type StatoNodeStatoSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  chiave?: Maybe<Scalars['String']>;
};


export type StatoNodeOrdiniArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


export type StatoNodeStatiOrdineArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type StatoNodeConnection = {
  __typename?: 'StatoNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StatoNodeEdge>>;
};

/** A Relay edge containing a `StatoNode` and its cursor. */
export type StatoNodeEdge = {
  __typename?: 'StatoNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<StatoNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type StatoOrdineNode = Node & {
  __typename?: 'StatoOrdineNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  ordine: OrdineNode;
  stato: StatoNode;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  pk?: Maybe<Scalars['Int']>;
};

export type StatoOrdineNodeConnection = {
  __typename?: 'StatoOrdineNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StatoOrdineNodeEdge>>;
};

/** A Relay edge containing a `StatoOrdineNode` and its cursor. */
export type StatoOrdineNodeEdge = {
  __typename?: 'StatoOrdineNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<StatoOrdineNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type StatoPaginaNode = {
  __typename?: 'StatoPaginaNode';
  id: Scalars['ID'];
  alias: Scalars['String'];
  nome: Scalars['String'];
  visibile: Scalars['Boolean'];
  news: NewsNodeConnection;
};


export type StatoPaginaNodeNewsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  categoriaId_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type StatoProdottoNode = {
  __typename?: 'StatoProdottoNode';
  id: Scalars['ID'];
  /** Un alias che identifichi lo stato */
  alias: Scalars['String'];
  codice?: Maybe<Scalars['String']>;
  azione?: Maybe<Scalars['String']>;
  inListing: Scalars['Boolean'];
  acquistabile: Scalars['Boolean'];
  escludiSconti: Scalars['Boolean'];
  /** 0 se non limitato */
  limiteAcquisto: Scalars['Int'];
  infoTitolo?: Maybe<Scalars['String']>;
  infoTitoloIt?: Maybe<Scalars['String']>;
  infoTitoloEn?: Maybe<Scalars['String']>;
  infoDescrizione?: Maybe<Scalars['String']>;
  infoDescrizioneIt?: Maybe<Scalars['String']>;
  infoDescrizioneEn?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  prodotti: ProdottoNodeConnection;
  pk?: Maybe<Scalars['Int']>;
};


export type StatoProdottoNodeProdottiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

export type TipoDescrizioneAggiuntivaProdottoType = {
  __typename?: 'TipoDescrizioneAggiuntivaProdottoType';
  id: Scalars['ID'];
  /** Un alias che identifichi la desc. aggiuntiva */
  alias: Scalars['String'];
  /** Chiave identificativa */
  chiave: Scalars['String'];
  /** Un titolo che potrebbe venir mostrato in scheda prodotto */
  titolo?: Maybe<Scalars['String']>;
  /** Un titolo che potrebbe venir mostrato in scheda prodotto */
  titoloIt?: Maybe<Scalars['String']>;
  /** Un titolo che potrebbe venir mostrato in scheda prodotto */
  titoloEn?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  descrizioniAggiuntive: Array<DescrizioneAggiuntivaProdottoType>;
};

export type TipoMediaNode = {
  __typename?: 'TipoMediaNode';
  id: Scalars['ID'];
  /** Un alias che identifichi la desc. aggiuntiva */
  alias: Scalars['String'];
  /** Chiave identificativa */
  chiave: Scalars['String'];
  ordinamento: Scalars['Int'];
  imagenews: Array<ImageNewsNode>;
  videonews: Array<VideoNewsNode>;
  imagecantine: Array<ImageCantineNode>;
  videocantine: Array<VideoCantineNode>;
  cantinainfobox: Array<CantinaInfoBoxNode>;
};

export type UpdateIndirizzo = {
  __typename?: 'UpdateIndirizzo';
  indirizzo?: Maybe<IndirizzoNode>;
};

export type UpdatePassword = {
  __typename?: 'UpdatePassword';
  message?: Maybe<Scalars['String']>;
};

export type UpdateUtente = {
  __typename?: 'UpdateUtente';
  utente?: Maybe<UtenteNode>;
  status?: Maybe<Scalars['Boolean']>;
  iscrizioneNewsletter?: Maybe<Scalars['Boolean']>;
};


export type UtenteContattoInput = {
  nome: Scalars['String'];
  cognome: Scalars['String'];
  email: Scalars['String'];
  messaggio?: Maybe<Scalars['String']>;
  motivazione: Scalars['String'];
};

export type UtenteInput = {
  nome: Scalars['String'];
  cognome: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  dataNascita?: Maybe<Scalars['Date']>;
  telefono?: Maybe<Scalars['String']>;
  privacyCommerciale: Scalars['Boolean'];
  privacy: Scalars['Boolean'];
};

export type UtenteNode = Node & {
  __typename?: 'UtenteNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  password: Scalars['String'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
  nome?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  customerIdStripe?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  email: Scalars['String'];
  telefono?: Maybe<Scalars['String']>;
  guest: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  dataDiNascita?: Maybe<Scalars['Date']>;
  cf?: Maybe<Scalars['String']>;
  piva?: Maybe<Scalars['String']>;
  codiceDestinatarioUnivoco?: Maybe<Scalars['String']>;
  privato: Scalars['Boolean'];
  privacy: Scalars['Boolean'];
  privacyCommerciale: Scalars['Boolean'];
  socialAuth: SocialNodeConnection;
  indirizzi: IndirizzoNodeConnection;
  promozioni: Array<PromozioneType>;
  ordini: OrdineNodeConnection;
  righeCarrello: Array<RigaCarrelloNode>;
  righeCheckout: Array<RigaCheckoutNode>;
  righeWishlist: Array<RigaWishlistNode>;
  pk?: Maybe<Scalars['Int']>;
  edit?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  dataRegistrazione?: Maybe<Scalars['String']>;
  isImpersonate?: Maybe<Scalars['Boolean']>;
  stopImpersonateUrl?: Maybe<Scalars['String']>;
};


export type UtenteNodeSocialAuthArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  uid?: Maybe<Scalars['String']>;
  uid_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  provider?: Maybe<Scalars['String']>;
  provider_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UtenteNodeIndirizziArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  isFatturazione?: Maybe<Scalars['Boolean']>;
};


export type UtenteNodeOrdiniArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type UtenteNodeConnection = {
  __typename?: 'UtenteNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UtenteNodeEdge>>;
};

/** A Relay edge containing a `UtenteNode` and its cursor. */
export type UtenteNodeEdge = {
  __typename?: 'UtenteNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<UtenteNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type UtenteUpdateInput = {
  nome?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  dataNascita?: Maybe<Scalars['Date']>;
  privacy?: Maybe<Scalars['Boolean']>;
  privacyCommerciale?: Maybe<Scalars['Boolean']>;
};

export type ValoreAttributoNode = Node & {
  __typename?: 'ValoreAttributoNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  alias: Scalars['String'];
  nome: Scalars['String'];
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  slugIt?: Maybe<Scalars['String']>;
  slugEn?: Maybe<Scalars['String']>;
  codice?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  categorie: CategoriaNodeConnection;
  news: NewsNodeConnection;
  cantine: CantineNodeConnection;
  cantineAsRegione: CantineNodeConnection;
  pk?: Maybe<Scalars['Int']>;
  principiAttiviImageThumb?: Maybe<Scalars['String']>;
};


export type ValoreAttributoNodeCategorieArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ValoreAttributoNodeNewsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  categoriaId_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type ValoreAttributoNodeCantineArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ValoreAttributoNodeCantineAsRegioneArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ValoreAttributoNodeConnection = {
  __typename?: 'ValoreAttributoNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ValoreAttributoNodeEdge>>;
};

/** A Relay edge containing a `ValoreAttributoNode` and its cursor. */
export type ValoreAttributoNodeEdge = {
  __typename?: 'ValoreAttributoNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ValoreAttributoNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type VarianteProdottoNode = Node & {
  __typename?: 'VarianteProdottoNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  prodotto: ProdottoNode;
  sku: Scalars['String'];
  attiva: Scalars['Boolean'];
  asin?: Maybe<Scalars['String']>;
  omaggioAsOmaggio: Array<OmaggioType>;
  omaggi: Array<OmaggioType>;
  bundle: ProdottoNodeConnection;
  prodottiBundle: Array<ProdottoBundleNode>;
  righeOrdine: RigaOrdineNodeConnection;
  righeCarrello: Array<RigaCarrelloNode>;
  righeCheckout: Array<RigaCheckoutNode>;
  pk?: Maybe<Scalars['Int']>;
  disponibilita?: Maybe<Scalars['Int']>;
};


export type VarianteProdottoNodeBundleArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};


export type VarianteProdottoNodeRigheOrdineArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type VarianteProdottoNodeConnection = {
  __typename?: 'VarianteProdottoNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VarianteProdottoNodeEdge>>;
};

/** A Relay edge containing a `VarianteProdottoNode` and its cursor. */
export type VarianteProdottoNodeEdge = {
  __typename?: 'VarianteProdottoNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<VarianteProdottoNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type VideoCantineNode = {
  __typename?: 'VideoCantineNode';
  /** Es: e2780IjFa5A */
  idYoutube?: Maybe<Scalars['String']>;
  mp4?: Maybe<Scalars['String']>;
  ogg?: Maybe<Scalars['String']>;
  webm?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  tipo?: Maybe<TipoMediaNode>;
  ordinamento: Scalars['Int'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  cantine: CantineNode;
  pk?: Maybe<Scalars['Int']>;
};

export type VideoNewsNode = {
  __typename?: 'VideoNewsNode';
  /** Es: e2780IjFa5A */
  idYoutube?: Maybe<Scalars['String']>;
  mp4?: Maybe<Scalars['String']>;
  ogg?: Maybe<Scalars['String']>;
  webm?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  tipo?: Maybe<TipoMediaNode>;
  ordinamento: Scalars['Int'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  news: NewsNode;
  pk?: Maybe<Scalars['Int']>;
};

export type VisitaGivNode = Node & {
  __typename?: 'VisitaGivNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  nome: Scalars['String'];
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  periodo?: Maybe<Scalars['String']>;
  periodoIt?: Maybe<Scalars['String']>;
  periodoEn?: Maybe<Scalars['String']>;
  degustazione?: Maybe<Scalars['String']>;
  degustazioneIt?: Maybe<Scalars['String']>;
  degustazioneEn?: Maybe<Scalars['String']>;
  durata?: Maybe<Scalars['String']>;
  durataIt?: Maybe<Scalars['String']>;
  durataEn?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  cantina?: Maybe<CantineNode>;
  pk?: Maybe<Scalars['Int']>;
};

export type VisitaGivNodeConnection = {
  __typename?: 'VisitaGivNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VisitaGivNodeEdge>>;
};

/** A Relay edge containing a `VisitaGivNode` and its cursor. */
export type VisitaGivNodeEdge = {
  __typename?: 'VisitaGivNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<VisitaGivNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type VisitaNode = Node & {
  __typename?: 'VisitaNode';
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionIt?: Maybe<Scalars['String']>;
  seoDescriptionEn?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleIt?: Maybe<Scalars['String']>;
  seoTitleEn?: Maybe<Scalars['String']>;
  seoH1?: Maybe<Scalars['String']>;
  titolo: Scalars['String'];
  titoloIt?: Maybe<Scalars['String']>;
  titoloEn?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  statoPubblicazione: PaginaStatoPubblicazione;
  /** Data e ora da quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaDal?: Maybe<Scalars['DateTime']>;
  /** Data e ora fino a quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaAl?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  slug: Scalars['String'];
  slugIt?: Maybe<Scalars['String']>;
  slugEn?: Maybe<Scalars['String']>;
  intro: Scalars['String'];
  introIt?: Maybe<Scalars['String']>;
  introEn?: Maybe<Scalars['String']>;
  nome: Scalars['String'];
  nomeIt?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  /** Durata in minuti */
  durata: Scalars['Int'];
  /** Prezzo */
  prezzo: Scalars['Float'];
  pk?: Maybe<Scalars['Int']>;
};

export type VotoType = {
  __typename?: 'VotoType';
  id: Scalars['ID'];
  prodotto: ProdottoNode;
  premio: PremioType;
  image?: Maybe<Scalars['String']>;
  testo: Scalars['String'];
  testoIt?: Maybe<Scalars['String']>;
  testoEn?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  valore?: Maybe<Scalars['Float']>;
  annata?: Maybe<Scalars['Int']>;
};

export type WishlistType = {
  __typename?: 'WishlistType';
  items?: Maybe<Array<Maybe<RigaWishlistNode>>>;
  numProdotti?: Maybe<Scalars['Int']>;
};

export type SetCheckoutMutationVariables = Exact<{
  sistemaPagamento: Scalars['Int'];
}>;


export type SetCheckoutMutation = (
  { __typename?: 'Mutation' }
  & { setCheckout?: Maybe<(
    { __typename?: 'SetCheckout' }
    & Pick<SetCheckout, 'url' | 'status' | 'message'>
  )> }
);

export type MenuChiSiamoQueryVariables = Exact<{ [key: string]: never; }>;


export type MenuChiSiamoQuery = (
  { __typename?: 'Query' }
  & { menuitem?: Maybe<(
    { __typename?: 'MenuItemNode' }
    & Pick<MenuItemNode, 'name' | 'key'>
    & { children: (
      { __typename?: 'MenuItemNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'MenuItemNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'MenuItemNode' }
          & Pick<MenuItemNode, 'key' | 'pk' | 'name' | 'description'>
          & { children: (
            { __typename?: 'MenuItemNodeConnection' }
            & { edges: Array<Maybe<(
              { __typename?: 'MenuItemNodeEdge' }
              & { node?: Maybe<(
                { __typename?: 'MenuItemNode' }
                & Pick<MenuItemNode, 'pk' | 'alias' | 'key' | 'name' | 'description' | 'image' | 'imageMobile'>
              )> }
            )>> }
          ) }
        )> }
      )>> }
    ) }
  )> }
);

export type MotivazioniContattoQueryVariables = Exact<{ [key: string]: never; }>;


export type MotivazioniContattoQuery = (
  { __typename?: 'Query' }
  & { motivazioniContatto?: Maybe<Array<Maybe<(
    { __typename?: 'MotivazioniContattoNode' }
    & Pick<MotivazioniContattoNode, 'nome' | 'chiave'>
  )>>> }
);

export type CreateUtenteContattoMutationVariables = Exact<{
  input: UtenteContattoInput;
}>;


export type CreateUtenteContattoMutation = (
  { __typename?: 'Mutation' }
  & { createUtenteContatto?: Maybe<(
    { __typename?: 'CreateUtenteContatto' }
    & Pick<CreateUtenteContatto, 'status'>
  )> }
);

export type CategorieFaqQueryVariables = Exact<{ [key: string]: never; }>;


export type CategorieFaqQuery = (
  { __typename?: 'Query' }
  & { categorieFaq?: Maybe<(
    { __typename?: 'CategoriaFaqNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'CategoriaFaqNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'CategoriaFaqNode' }
        & Pick<CategoriaFaqNode, 'pk' | 'nome' | 'chiave'>
        & { faq?: Maybe<Array<Maybe<(
          { __typename?: 'FaqNode' }
          & Pick<FaqNode, 'pk' | 'nome' | 'descrizione'>
        )>>> }
      )> }
    )>> }
  )> }
);

export type MenuHeroQueryVariables = Exact<{ [key: string]: never; }>;


export type MenuHeroQuery = (
  { __typename?: 'Query' }
  & { menuitem?: Maybe<(
    { __typename?: 'MenuItemNode' }
    & { children: (
      { __typename?: 'MenuItemNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'MenuItemNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'MenuItemNode' }
          & Pick<MenuItemNode, 'id' | 'pk' | 'image' | 'imageTablet' | 'imageMobile'>
          & { url: MenuItemNode['finalUrl'] }
        )> }
      )>> }
    ) }
  )> }
);

export type MenuAbbinamentiQueryVariables = Exact<{ [key: string]: never; }>;


export type MenuAbbinamentiQuery = (
  { __typename?: 'Query' }
  & { menuitem?: Maybe<(
    { __typename?: 'MenuItemNode' }
    & { children: (
      { __typename?: 'MenuItemNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'MenuItemNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'MenuItemNode' }
          & Pick<MenuItemNode, 'id' | 'pk' | 'stile'>
          & { chiave: MenuItemNode['key'], title: MenuItemNode['name'], url: MenuItemNode['finalUrl'] }
        )> }
      )>> }
    ) }
  )> }
);

export type MenuBoxMarketingQueryVariables = Exact<{ [key: string]: never; }>;


export type MenuBoxMarketingQuery = (
  { __typename?: 'Query' }
  & { menuitem?: Maybe<(
    { __typename?: 'MenuItemNode' }
    & { children: (
      { __typename?: 'MenuItemNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'MenuItemNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'MenuItemNode' }
          & Pick<MenuItemNode, 'description'>
          & { title: MenuItemNode['name'], imageUrl: MenuItemNode['image'], url: MenuItemNode['finalUrl'] }
        )> }
      )>> }
    ) }
  )> }
);

export type MenuTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type MenuTagsQuery = (
  { __typename?: 'Query' }
  & { menuitem?: Maybe<(
    { __typename?: 'MenuItemNode' }
    & { children: (
      { __typename?: 'MenuItemNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'MenuItemNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'MenuItemNode' }
          & Pick<MenuItemNode, 'id' | 'pk' | 'name'>
          & { url: MenuItemNode['finalUrl'] }
        )> }
      )>> }
    ) }
  )> }
);

export type GetCategorieNewsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategorieNewsQuery = (
  { __typename?: 'Query' }
  & { categorieNews?: Maybe<(
    { __typename?: 'CategoriaNewsNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'CategoriaNewsNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'CategoriaNewsNode' }
        & Pick<CategoriaNewsNode, 'pk' | 'nome'>
      )> }
    )>> }
  )> }
);

export type GetAllNewsQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  categorieIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type GetAllNewsQuery = (
  { __typename?: 'Query' }
  & { allNews?: Maybe<(
    { __typename?: 'NewsNodeConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'NewsNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'NewsNode' }
        & Pick<NewsNode, 'pk' | 'titolo' | 'sottotitolo' | 'data' | 'url' | 'listingImage'>
        & { categoria?: Maybe<(
          { __typename?: 'CategoriaNewsNode' }
          & Pick<CategoriaNewsNode, 'pk' | 'nome'>
        )> }
      )> }
    )>> }
  )> }
);

export type GetNewsBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetNewsBySlugQuery = (
  { __typename?: 'Query' }
  & { news?: Maybe<(
    { __typename?: 'NewsNode' }
    & Pick<NewsNode, 'contentTypeId' | 'adminUrl' | 'modelClass' | 'pk' | 'titolo' | 'sottotitolo' | 'testo' | 'data' | 'mainImage'>
    & { categoria?: Maybe<(
      { __typename?: 'CategoriaNewsNode' }
      & Pick<CategoriaNewsNode, 'nome'>
    )>, newsCorrelate?: Maybe<Array<Maybe<(
      { __typename?: 'NewsNode' }
      & Pick<NewsNode, 'pk' | 'titolo' | 'sottotitolo' | 'data' | 'listingImage' | 'url'>
      & { categoria?: Maybe<(
        { __typename?: 'CategoriaNewsNode' }
        & Pick<CategoriaNewsNode, 'pk' | 'nome'>
      )> }
    )>>>, prodottiCorrelati?: Maybe<Array<Maybe<(
      { __typename?: 'ProdottoNode' }
      & Pick<ProdottoNode, 'contentTypeId' | 'pk' | 'slug' | 'alias' | 'nomeFantasia' | 'nome' | 'descrizione' | 'mainImage' | 'url' | 'codice' | 'prezzo' | 'prezzoScontato' | 'scontoPercentuale' | 'inWishlist' | 'disponibilita' | 'acquistabile' | 'magnum' | 'bundle'>
      & { varianteProdotto?: Maybe<(
        { __typename?: 'VarianteProdottoNode' }
        & Pick<VarianteProdottoNode, 'pk'>
      )>, promoApplicata?: Maybe<(
        { __typename?: 'PromozioneType' }
        & Pick<PromozioneType, 'percentuale' | 'valore' | 'alias' | 'descrizione' | 'dal' | 'al' | 'timeUntil' | 'mostraCounter'>
      )>, omaggio?: Maybe<(
        { __typename?: 'OmaggioType' }
        & Pick<OmaggioType, 'nome' | 'descrizione'>
        & { prodottoOmaggio: (
          { __typename?: 'VarianteProdottoNode' }
          & { prodotto: (
            { __typename?: 'ProdottoNode' }
            & Pick<ProdottoNode, 'alias' | 'nome' | 'mainImage'>
          ) }
        ) }
      )>, categoria?: Maybe<(
        { __typename?: 'CategoriaNode' }
        & Pick<CategoriaNode, 'alias'>
      )>, cantina?: Maybe<(
        { __typename?: 'CantineNode' }
        & Pick<CantineNode, 'nome'>
      )>, voti?: Maybe<Array<Maybe<(
        { __typename?: 'VotoType' }
        & Pick<VotoType, 'testo' | 'valore' | 'annata' | 'ordinamento' | 'id'>
        & { premio: (
          { __typename?: 'PremioType' }
          & Pick<PremioType, 'nome' | 'sigla' | 'chiave'>
        ) }
      )>>> }
    )>>> }
  )> }
);

export type CreateUtenteNewsletterMutationVariables = Exact<{
  input: RichiestaIscrizioneNewsletterInput;
}>;


export type CreateUtenteNewsletterMutation = (
  { __typename?: 'Mutation' }
  & { createUtenteNewsletter?: Maybe<(
    { __typename?: 'CreateUtenteNewsletter' }
    & Pick<CreateUtenteNewsletter, 'status' | 'message'>
  )> }
);

export type ConfirmUtenteNewsletterMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ConfirmUtenteNewsletterMutation = (
  { __typename?: 'Mutation' }
  & { confirmUtenteNewsletter?: Maybe<(
    { __typename?: 'ConfirmUtenteNewsletter' }
    & Pick<ConfirmUtenteNewsletter, 'status'>
  )> }
);

export type OrdiniQueryVariables = Exact<{ [key: string]: never; }>;


export type OrdiniQuery = (
  { __typename?: 'Query' }
  & { ordini?: Maybe<(
    { __typename?: 'OrdineNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'OrdineNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'OrdineNode' }
        & Pick<OrdineNode, 'pk' | 'data' | 'codice' | 'estero' | 'prezzoTotaleScontato'>
        & { spedizioneOrdine?: Maybe<(
          { __typename?: 'SpedizioneOrdineNode' }
          & Pick<SpedizioneOrdineNode, 'tracking'>
        )>, stato?: Maybe<(
          { __typename?: 'StatoNode' }
          & Pick<StatoNode, 'nome' | 'alias' | 'chiave'>
        )>, righeOrdine?: Maybe<Array<Maybe<(
          { __typename?: 'RigaOrdineNode' }
          & Pick<RigaOrdineNode, 'pk' | 'tipologia' | 'quantitaOrdinata'>
          & { prodotto?: Maybe<(
            { __typename?: 'VarianteProdottoNode' }
            & Pick<VarianteProdottoNode, 'pk'>
            & { prodotto: (
              { __typename?: 'ProdottoNode' }
              & Pick<ProdottoNode, 'nomeFantasia' | 'nome' | 'mainImage'>
            ) }
          )> }
        )>>> }
      )> }
    )>> }
  )> }
);

export type OrdineQueryVariables = Exact<{
  pk: Scalars['Int'];
}>;


export type OrdineQuery = (
  { __typename?: 'Query' }
  & { ordine?: Maybe<(
    { __typename?: 'OrdineNode' }
    & Pick<OrdineNode, 'pk' | 'data' | 'codice' | 'hasFattura' | 'hasRicevuta' | 'includiMessaggio' | 'estero' | 'prezzoTotale' | 'prezzoTotaleScontato' | 'promozioneValoreAssoluto'>
    & { indirizzoSpedizione?: Maybe<(
      { __typename?: 'IndirizzoOrdineNode' }
      & Pick<IndirizzoOrdineNode, 'nome' | 'cognome' | 'indirizzo' | 'numeroCivico' | 'cap' | 'citta' | 'provincia'>
    )>, indirizzoFatturazione?: Maybe<(
      { __typename?: 'IndirizzoOrdineNode' }
      & Pick<IndirizzoOrdineNode, 'nome' | 'cognome' | 'indirizzo' | 'numeroCivico' | 'cap' | 'citta' | 'provincia' | 'cf' | 'piva'>
    )>, sistemaPagamento?: Maybe<(
      { __typename?: 'SistemaPagamentoType' }
      & Pick<SistemaPagamentoType, 'nome'>
    )>, spedizioneOrdine?: Maybe<(
      { __typename?: 'SpedizioneOrdineNode' }
      & Pick<SpedizioneOrdineNode, 'tracking'>
    )>, stato?: Maybe<(
      { __typename?: 'StatoNode' }
      & Pick<StatoNode, 'nome' | 'alias' | 'chiave'>
    )>, promozione?: Maybe<(
      { __typename?: 'PromozioneType' }
      & Pick<PromozioneType, 'alias'>
    )>, righeOrdine?: Maybe<Array<Maybe<(
      { __typename?: 'RigaOrdineNode' }
      & Pick<RigaOrdineNode, 'pk' | 'tipologia' | 'quantitaOrdinata' | 'prezzoUnitario' | 'prezzoUnitarioScontato' | 'prezzoTotaleScontato' | 'omaggio'>
      & { prodotto?: Maybe<(
        { __typename?: 'VarianteProdottoNode' }
        & Pick<VarianteProdottoNode, 'pk'>
        & { prodotto: (
          { __typename?: 'ProdottoNode' }
          & Pick<ProdottoNode, 'nomeFantasia' | 'mainImage'>
          & { cantina?: Maybe<(
            { __typename?: 'CantineNode' }
            & Pick<CantineNode, 'nome'>
          )> }
        ) }
      )> }
    )>>> }
  )> }
);

export type GetPaginaQueryVariables = Exact<{
  chiave: Scalars['String'];
  contentTypeId: Scalars['Int'];
  objectId: Scalars['Int'];
}>;


export type GetPaginaQuery = (
  { __typename?: 'Query' }
  & { pagina?: Maybe<(
    { __typename?: 'PaginaNode' }
    & Pick<PaginaNode, 'adminUrl' | 'chiave'>
    & { breadcrumbs?: Maybe<Array<Maybe<(
      { __typename?: 'BreadcrumbType' }
      & Pick<BreadcrumbType, 'titolo' | 'url'>
    )>>>, seo?: Maybe<(
      { __typename?: 'SeoType' }
      & Pick<SeoType, 'title' | 'description' | 'image'>
    )> }
  )> }
);

export type SetProdottiQueryVariables = Exact<{
  chiave: Scalars['String'];
}>;


export type SetProdottiQuery = (
  { __typename?: 'Query' }
  & { setProdotti?: Maybe<Array<Maybe<(
    { __typename?: 'ProdottoNode' }
    & Pick<ProdottoNode, 'pk' | 'nome' | 'nomeFantasia' | 'alias' | 'slug'>
  )>>> }
);

export type ProdottoQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProdottoQuery = (
  { __typename?: 'Query' }
  & { prodotto?: Maybe<(
    { __typename?: 'ProdottoRedirectNode' }
    & Pick<ProdottoRedirectNode, 'statusCodeRedirect'>
    & { redirect?: Maybe<(
      { __typename?: 'CategoriaNode' }
      & Pick<CategoriaNode, 'pk' | 'url'>
    )>, prodotto?: Maybe<(
      { __typename?: 'ProdottoNode' }
      & Pick<ProdottoNode, 'contentTypeId' | 'modelClass' | 'pk' | 'adminUrl' | 'slug' | 'alias' | 'nomeFantasia' | 'nome' | 'descrizione' | 'mainImage' | 'url' | 'codice' | 'prezzo' | 'prezzoOmnibus' | 'prezzoScontato' | 'scontoPercentuale' | 'inWishlist' | 'disponibilita' | 'acquistabile' | 'mondo' | 'bundle' | 'numeroPezziBundle' | 'magnum'>
      & { varianteProdotto?: Maybe<(
        { __typename?: 'VarianteProdottoNode' }
        & Pick<VarianteProdottoNode, 'pk'>
      )>, breadcrumbs?: Maybe<Array<Maybe<(
        { __typename?: 'BreadcrumbItemType' }
        & Pick<BreadcrumbItemType, 'titolo' | 'url'>
      )>>>, promoApplicata?: Maybe<(
        { __typename?: 'PromozioneType' }
        & Pick<PromozioneType, 'percentuale' | 'valore' | 'alias' | 'descrizione' | 'dal' | 'al' | 'timeUntil' | 'mostraCounter'>
      )>, omaggio?: Maybe<(
        { __typename?: 'OmaggioType' }
        & Pick<OmaggioType, 'nome' | 'descrizione'>
        & { prodottoOmaggio: (
          { __typename?: 'VarianteProdottoNode' }
          & { prodotto: (
            { __typename?: 'ProdottoNode' }
            & Pick<ProdottoNode, 'alias' | 'nome' | 'mainImage'>
          ) }
        ) }
      )>, prodottiBundle?: Maybe<Array<Maybe<(
        { __typename?: 'ProdottoBundleNode' }
        & Pick<ProdottoBundleNode, 'quantita'>
        & { variante: (
          { __typename?: 'VarianteProdottoNode' }
          & { prodotto: (
            { __typename?: 'ProdottoNode' }
            & Pick<ProdottoNode, 'pk' | 'nomeFantasia' | 'nome' | 'descrizione' | 'mainImage' | 'url'>
          ) }
        ) }
      )>>>, categoria?: Maybe<(
        { __typename?: 'CategoriaNode' }
        & Pick<CategoriaNode, 'alias'>
      )>, cantina?: Maybe<(
        { __typename?: 'CantineNode' }
        & Pick<CantineNode, 'nome' | 'slug'>
      )>, attributi?: Maybe<(
        { __typename?: 'AttributiProdottoType' }
        & Pick<AttributiProdottoType, 'annata' | 'vitigno' | 'abbinamento' | 'gradazione' | 'formato' | 'colore' | 'consumoIdeale' | 'servizio'>
      )>, datiSchedaTecnica?: Maybe<Array<Maybe<(
        { __typename?: 'DescrizioneAggiuntivaProdottoType' }
        & Pick<DescrizioneAggiuntivaProdottoType, 'chiave' | 'alias' | 'testo' | 'titolo'>
      )>>>, voti?: Maybe<Array<Maybe<(
        { __typename?: 'VotoType' }
        & Pick<VotoType, 'testo' | 'valore' | 'annata' | 'ordinamento' | 'id'>
        & { premio: (
          { __typename?: 'PremioType' }
          & Pick<PremioType, 'nome' | 'sigla' | 'chiave'>
        ) }
      )>>> }
    )> }
  )> }
);

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { tokenAuth?: Maybe<(
    { __typename?: 'ObtainJSONWebToken' }
    & Pick<ObtainJsonWebToken, 'token'>
    & { user?: Maybe<(
      { __typename?: 'UtenteNode' }
      & Pick<UtenteNode, 'pk'>
    )> }
  )> }
);

export type SocialAuthMutationVariables = Exact<{
  provider: Scalars['String'];
  accessToken: Scalars['String'];
}>;


export type SocialAuthMutation = (
  { __typename?: 'Mutation' }
  & { socialAuth?: Maybe<(
    { __typename?: 'SocialAuthSignalJWT' }
    & Pick<SocialAuthSignalJwt, 'token'>
    & { social?: Maybe<(
      { __typename?: 'SocialType' }
      & Pick<SocialType, 'uid'>
      & { user: (
        { __typename?: 'UtenteNode' }
        & Pick<UtenteNode, 'pk'>
      ) }
    )> }
  )> }
);

export type CreateUtenteMutationVariables = Exact<{
  input: UtenteInput;
}>;


export type CreateUtenteMutation = (
  { __typename?: 'Mutation' }
  & { createUtente?: Maybe<(
    { __typename?: 'CreateUtente' }
    & Pick<CreateUtente, 'status'>
    & { utente?: Maybe<(
      { __typename?: 'UtenteNode' }
      & Pick<UtenteNode, 'pk' | 'dataRegistrazione'>
    )> }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetPassword' }
    & Pick<ResetPassword, 'status'>
  )> }
);

export type RecuperaPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RecuperaPasswordMutation = (
  { __typename?: 'Mutation' }
  & { recuperaPassword?: Maybe<(
    { __typename?: 'RecuperaPassword' }
    & Pick<RecuperaPassword, 'status'>
  )> }
);

export type ConfirmUtenteMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ConfirmUtenteMutation = (
  { __typename?: 'Mutation' }
  & { confirmUtente?: Maybe<(
    { __typename?: 'ConfirmUtente' }
    & Pick<ConfirmUtente, 'status'>
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'UtenteNode' }
    & Pick<UtenteNode, 'pk' | 'email' | 'nome' | 'cognome' | 'completed' | 'isStaff' | 'edit' | 'privacy' | 'privacyCommerciale' | 'dataRegistrazione' | 'isImpersonate' | 'stopImpersonateUrl'>
  )> }
);

export type UpdateUtenteMutationVariables = Exact<{
  input: UtenteUpdateInput;
}>;


export type UpdateUtenteMutation = (
  { __typename?: 'Mutation' }
  & { updateUtente?: Maybe<(
    { __typename?: 'UpdateUtente' }
    & Pick<UpdateUtente, 'iscrizioneNewsletter'>
    & { utente?: Maybe<(
      { __typename?: 'UtenteNode' }
      & Pick<UtenteNode, 'pk' | 'nome' | 'cognome' | 'email'>
    )> }
  )> }
);

export type UpdatePasswordMutationVariables = Exact<{
  input: PasswordUpdateInput;
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & { updatePassword?: Maybe<(
    { __typename?: 'UpdatePassword' }
    & Pick<UpdatePassword, 'message'>
  )> }
);


export const SetCheckoutDocument = gql`
    mutation SetCheckout($sistemaPagamento: Int!) {
  setCheckout(sistemaPagamento: $sistemaPagamento) {
    url
    status
    message
  }
}
    `;
export type SetCheckoutMutationFn = Apollo.MutationFunction<SetCheckoutMutation, SetCheckoutMutationVariables>;

/**
 * __useSetCheckoutMutation__
 *
 * To run a mutation, you first call `useSetCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCheckoutMutation, { data, loading, error }] = useSetCheckoutMutation({
 *   variables: {
 *      sistemaPagamento: // value for 'sistemaPagamento'
 *   },
 * });
 */
export function useSetCheckoutMutation(baseOptions?: Apollo.MutationHookOptions<SetCheckoutMutation, SetCheckoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCheckoutMutation, SetCheckoutMutationVariables>(SetCheckoutDocument, options);
      }
export type SetCheckoutMutationHookResult = ReturnType<typeof useSetCheckoutMutation>;
export type SetCheckoutMutationResult = Apollo.MutationResult<SetCheckoutMutation>;
export type SetCheckoutMutationOptions = Apollo.BaseMutationOptions<SetCheckoutMutation, SetCheckoutMutationVariables>;
export const MenuChiSiamoDocument = gql`
    query MenuChiSiamo {
  menuitem(key: "chi-siamo") {
    name
    key
    children(attivo: true) {
      edges {
        node {
          key
          pk
          name
          description
          children(attivo: true) {
            edges {
              node {
                pk
                alias
                key
                name
                description
                image
                imageMobile
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMenuChiSiamoQuery__
 *
 * To run a query within a React component, call `useMenuChiSiamoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuChiSiamoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuChiSiamoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMenuChiSiamoQuery(baseOptions?: Apollo.QueryHookOptions<MenuChiSiamoQuery, MenuChiSiamoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuChiSiamoQuery, MenuChiSiamoQueryVariables>(MenuChiSiamoDocument, options);
      }
export function useMenuChiSiamoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuChiSiamoQuery, MenuChiSiamoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuChiSiamoQuery, MenuChiSiamoQueryVariables>(MenuChiSiamoDocument, options);
        }
export type MenuChiSiamoQueryHookResult = ReturnType<typeof useMenuChiSiamoQuery>;
export type MenuChiSiamoLazyQueryHookResult = ReturnType<typeof useMenuChiSiamoLazyQuery>;
export type MenuChiSiamoQueryResult = Apollo.QueryResult<MenuChiSiamoQuery, MenuChiSiamoQueryVariables>;
export const MotivazioniContattoDocument = gql`
    query MotivazioniContatto {
  motivazioniContatto {
    nome
    chiave
  }
}
    `;

/**
 * __useMotivazioniContattoQuery__
 *
 * To run a query within a React component, call `useMotivazioniContattoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMotivazioniContattoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMotivazioniContattoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMotivazioniContattoQuery(baseOptions?: Apollo.QueryHookOptions<MotivazioniContattoQuery, MotivazioniContattoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MotivazioniContattoQuery, MotivazioniContattoQueryVariables>(MotivazioniContattoDocument, options);
      }
export function useMotivazioniContattoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MotivazioniContattoQuery, MotivazioniContattoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MotivazioniContattoQuery, MotivazioniContattoQueryVariables>(MotivazioniContattoDocument, options);
        }
export type MotivazioniContattoQueryHookResult = ReturnType<typeof useMotivazioniContattoQuery>;
export type MotivazioniContattoLazyQueryHookResult = ReturnType<typeof useMotivazioniContattoLazyQuery>;
export type MotivazioniContattoQueryResult = Apollo.QueryResult<MotivazioniContattoQuery, MotivazioniContattoQueryVariables>;
export const CreateUtenteContattoDocument = gql`
    mutation CreateUtenteContatto($input: UtenteContattoInput!) {
  createUtenteContatto(input: $input) {
    status
  }
}
    `;
export type CreateUtenteContattoMutationFn = Apollo.MutationFunction<CreateUtenteContattoMutation, CreateUtenteContattoMutationVariables>;

/**
 * __useCreateUtenteContattoMutation__
 *
 * To run a mutation, you first call `useCreateUtenteContattoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUtenteContattoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUtenteContattoMutation, { data, loading, error }] = useCreateUtenteContattoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUtenteContattoMutation(baseOptions?: Apollo.MutationHookOptions<CreateUtenteContattoMutation, CreateUtenteContattoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUtenteContattoMutation, CreateUtenteContattoMutationVariables>(CreateUtenteContattoDocument, options);
      }
export type CreateUtenteContattoMutationHookResult = ReturnType<typeof useCreateUtenteContattoMutation>;
export type CreateUtenteContattoMutationResult = Apollo.MutationResult<CreateUtenteContattoMutation>;
export type CreateUtenteContattoMutationOptions = Apollo.BaseMutationOptions<CreateUtenteContattoMutation, CreateUtenteContattoMutationVariables>;
export const CategorieFaqDocument = gql`
    query CategorieFaq {
  categorieFaq {
    edges {
      node {
        pk
        nome
        chiave
        faq {
          pk
          nome
          descrizione
        }
      }
    }
  }
}
    `;

/**
 * __useCategorieFaqQuery__
 *
 * To run a query within a React component, call `useCategorieFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategorieFaqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategorieFaqQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategorieFaqQuery(baseOptions?: Apollo.QueryHookOptions<CategorieFaqQuery, CategorieFaqQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategorieFaqQuery, CategorieFaqQueryVariables>(CategorieFaqDocument, options);
      }
export function useCategorieFaqLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategorieFaqQuery, CategorieFaqQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategorieFaqQuery, CategorieFaqQueryVariables>(CategorieFaqDocument, options);
        }
export type CategorieFaqQueryHookResult = ReturnType<typeof useCategorieFaqQuery>;
export type CategorieFaqLazyQueryHookResult = ReturnType<typeof useCategorieFaqLazyQuery>;
export type CategorieFaqQueryResult = Apollo.QueryResult<CategorieFaqQuery, CategorieFaqQueryVariables>;
export const MenuHeroDocument = gql`
    query MenuHero {
  menuitem(key: "hero") {
    children(attivo: true) {
      edges {
        node {
          id
          pk
          image
          imageTablet
          imageMobile
          url: finalUrl
        }
      }
    }
  }
}
    `;

/**
 * __useMenuHeroQuery__
 *
 * To run a query within a React component, call `useMenuHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuHeroQuery({
 *   variables: {
 *   },
 * });
 */
export function useMenuHeroQuery(baseOptions?: Apollo.QueryHookOptions<MenuHeroQuery, MenuHeroQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuHeroQuery, MenuHeroQueryVariables>(MenuHeroDocument, options);
      }
export function useMenuHeroLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuHeroQuery, MenuHeroQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuHeroQuery, MenuHeroQueryVariables>(MenuHeroDocument, options);
        }
export type MenuHeroQueryHookResult = ReturnType<typeof useMenuHeroQuery>;
export type MenuHeroLazyQueryHookResult = ReturnType<typeof useMenuHeroLazyQuery>;
export type MenuHeroQueryResult = Apollo.QueryResult<MenuHeroQuery, MenuHeroQueryVariables>;
export const MenuAbbinamentiDocument = gql`
    query MenuAbbinamenti {
  menuitem(key: "abbinamenti") {
    children(attivo: true) {
      edges {
        node {
          id
          pk
          chiave: key
          title: name
          url: finalUrl
          stile
        }
      }
    }
  }
}
    `;

/**
 * __useMenuAbbinamentiQuery__
 *
 * To run a query within a React component, call `useMenuAbbinamentiQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuAbbinamentiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuAbbinamentiQuery({
 *   variables: {
 *   },
 * });
 */
export function useMenuAbbinamentiQuery(baseOptions?: Apollo.QueryHookOptions<MenuAbbinamentiQuery, MenuAbbinamentiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuAbbinamentiQuery, MenuAbbinamentiQueryVariables>(MenuAbbinamentiDocument, options);
      }
export function useMenuAbbinamentiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuAbbinamentiQuery, MenuAbbinamentiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuAbbinamentiQuery, MenuAbbinamentiQueryVariables>(MenuAbbinamentiDocument, options);
        }
export type MenuAbbinamentiQueryHookResult = ReturnType<typeof useMenuAbbinamentiQuery>;
export type MenuAbbinamentiLazyQueryHookResult = ReturnType<typeof useMenuAbbinamentiLazyQuery>;
export type MenuAbbinamentiQueryResult = Apollo.QueryResult<MenuAbbinamentiQuery, MenuAbbinamentiQueryVariables>;
export const MenuBoxMarketingDocument = gql`
    query MenuBoxMarketing {
  menuitem(key: "box-marketing") {
    children(attivo: true) {
      edges {
        node {
          description
          title: name
          imageUrl: image
          url: finalUrl
        }
      }
    }
  }
}
    `;

/**
 * __useMenuBoxMarketingQuery__
 *
 * To run a query within a React component, call `useMenuBoxMarketingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuBoxMarketingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuBoxMarketingQuery({
 *   variables: {
 *   },
 * });
 */
export function useMenuBoxMarketingQuery(baseOptions?: Apollo.QueryHookOptions<MenuBoxMarketingQuery, MenuBoxMarketingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuBoxMarketingQuery, MenuBoxMarketingQueryVariables>(MenuBoxMarketingDocument, options);
      }
export function useMenuBoxMarketingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuBoxMarketingQuery, MenuBoxMarketingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuBoxMarketingQuery, MenuBoxMarketingQueryVariables>(MenuBoxMarketingDocument, options);
        }
export type MenuBoxMarketingQueryHookResult = ReturnType<typeof useMenuBoxMarketingQuery>;
export type MenuBoxMarketingLazyQueryHookResult = ReturnType<typeof useMenuBoxMarketingLazyQuery>;
export type MenuBoxMarketingQueryResult = Apollo.QueryResult<MenuBoxMarketingQuery, MenuBoxMarketingQueryVariables>;
export const MenuTagsDocument = gql`
    query MenuTags {
  menuitem(key: "tags") {
    children(attivo: true) {
      edges {
        node {
          id
          pk
          name
          url: finalUrl
        }
      }
    }
  }
}
    `;

/**
 * __useMenuTagsQuery__
 *
 * To run a query within a React component, call `useMenuTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMenuTagsQuery(baseOptions?: Apollo.QueryHookOptions<MenuTagsQuery, MenuTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuTagsQuery, MenuTagsQueryVariables>(MenuTagsDocument, options);
      }
export function useMenuTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuTagsQuery, MenuTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuTagsQuery, MenuTagsQueryVariables>(MenuTagsDocument, options);
        }
export type MenuTagsQueryHookResult = ReturnType<typeof useMenuTagsQuery>;
export type MenuTagsLazyQueryHookResult = ReturnType<typeof useMenuTagsLazyQuery>;
export type MenuTagsQueryResult = Apollo.QueryResult<MenuTagsQuery, MenuTagsQueryVariables>;
export const GetCategorieNewsDocument = gql`
    query getCategorieNews {
  categorieNews {
    edges {
      node {
        pk
        nome
      }
    }
  }
}
    `;

/**
 * __useGetCategorieNewsQuery__
 *
 * To run a query within a React component, call `useGetCategorieNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategorieNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategorieNewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategorieNewsQuery(baseOptions?: Apollo.QueryHookOptions<GetCategorieNewsQuery, GetCategorieNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategorieNewsQuery, GetCategorieNewsQueryVariables>(GetCategorieNewsDocument, options);
      }
export function useGetCategorieNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategorieNewsQuery, GetCategorieNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategorieNewsQuery, GetCategorieNewsQueryVariables>(GetCategorieNewsDocument, options);
        }
export type GetCategorieNewsQueryHookResult = ReturnType<typeof useGetCategorieNewsQuery>;
export type GetCategorieNewsLazyQueryHookResult = ReturnType<typeof useGetCategorieNewsLazyQuery>;
export type GetCategorieNewsQueryResult = Apollo.QueryResult<GetCategorieNewsQuery, GetCategorieNewsQueryVariables>;
export const GetAllNewsDocument = gql`
    query getAllNews($offset: Int, $first: Int, $categorieIds: [ID]) {
  allNews(offset: $offset, first: $first, categoriaId_In: $categorieIds) {
    pageInfo {
      hasNextPage
    }
    edges {
      node {
        pk
        titolo
        sottotitolo
        data
        url
        listingImage
        categoria {
          pk
          nome
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllNewsQuery__
 *
 * To run a query within a React component, call `useGetAllNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNewsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *      categorieIds: // value for 'categorieIds'
 *   },
 * });
 */
export function useGetAllNewsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllNewsQuery, GetAllNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllNewsQuery, GetAllNewsQueryVariables>(GetAllNewsDocument, options);
      }
export function useGetAllNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllNewsQuery, GetAllNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllNewsQuery, GetAllNewsQueryVariables>(GetAllNewsDocument, options);
        }
export type GetAllNewsQueryHookResult = ReturnType<typeof useGetAllNewsQuery>;
export type GetAllNewsLazyQueryHookResult = ReturnType<typeof useGetAllNewsLazyQuery>;
export type GetAllNewsQueryResult = Apollo.QueryResult<GetAllNewsQuery, GetAllNewsQueryVariables>;
export const GetNewsBySlugDocument = gql`
    query getNewsBySlug($slug: String!) {
  news(slug: $slug) {
    contentTypeId
    adminUrl
    modelClass
    pk
    titolo
    sottotitolo
    testo
    data
    mainImage
    categoria {
      nome
    }
    newsCorrelate {
      pk
      titolo
      sottotitolo
      data
      listingImage
      url
      categoria {
        pk
        nome
      }
    }
    prodottiCorrelati {
      contentTypeId
      pk
      varianteProdotto {
        pk
      }
      slug
      alias
      nomeFantasia
      nome
      descrizione
      mainImage
      url
      codice
      prezzo
      prezzoScontato
      scontoPercentuale
      promoApplicata {
        percentuale
        valore
        alias
        descrizione
        dal
        al
        timeUntil
        mostraCounter
      }
      omaggio {
        nome
        descrizione
        prodottoOmaggio {
          prodotto {
            alias
            nome
            mainImage
          }
        }
      }
      inWishlist
      disponibilita
      acquistabile
      url
      magnum
      bundle
      categoria {
        alias
      }
      cantina {
        nome
      }
      voti {
        premio {
          nome
          sigla
          chiave
        }
        testo
        valore
        annata
        ordinamento
        id
      }
    }
  }
}
    `;

/**
 * __useGetNewsBySlugQuery__
 *
 * To run a query within a React component, call `useGetNewsBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetNewsBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetNewsBySlugQuery, GetNewsBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewsBySlugQuery, GetNewsBySlugQueryVariables>(GetNewsBySlugDocument, options);
      }
export function useGetNewsBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsBySlugQuery, GetNewsBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewsBySlugQuery, GetNewsBySlugQueryVariables>(GetNewsBySlugDocument, options);
        }
export type GetNewsBySlugQueryHookResult = ReturnType<typeof useGetNewsBySlugQuery>;
export type GetNewsBySlugLazyQueryHookResult = ReturnType<typeof useGetNewsBySlugLazyQuery>;
export type GetNewsBySlugQueryResult = Apollo.QueryResult<GetNewsBySlugQuery, GetNewsBySlugQueryVariables>;
export const CreateUtenteNewsletterDocument = gql`
    mutation createUtenteNewsletter($input: RichiestaIscrizioneNewsletterInput!) {
  createUtenteNewsletter(input: $input) {
    status
    message
  }
}
    `;
export type CreateUtenteNewsletterMutationFn = Apollo.MutationFunction<CreateUtenteNewsletterMutation, CreateUtenteNewsletterMutationVariables>;

/**
 * __useCreateUtenteNewsletterMutation__
 *
 * To run a mutation, you first call `useCreateUtenteNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUtenteNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUtenteNewsletterMutation, { data, loading, error }] = useCreateUtenteNewsletterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUtenteNewsletterMutation(baseOptions?: Apollo.MutationHookOptions<CreateUtenteNewsletterMutation, CreateUtenteNewsletterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUtenteNewsletterMutation, CreateUtenteNewsletterMutationVariables>(CreateUtenteNewsletterDocument, options);
      }
export type CreateUtenteNewsletterMutationHookResult = ReturnType<typeof useCreateUtenteNewsletterMutation>;
export type CreateUtenteNewsletterMutationResult = Apollo.MutationResult<CreateUtenteNewsletterMutation>;
export type CreateUtenteNewsletterMutationOptions = Apollo.BaseMutationOptions<CreateUtenteNewsletterMutation, CreateUtenteNewsletterMutationVariables>;
export const ConfirmUtenteNewsletterDocument = gql`
    mutation confirmUtenteNewsletter($token: String!) {
  confirmUtenteNewsletter(token: $token) {
    status
  }
}
    `;
export type ConfirmUtenteNewsletterMutationFn = Apollo.MutationFunction<ConfirmUtenteNewsletterMutation, ConfirmUtenteNewsletterMutationVariables>;

/**
 * __useConfirmUtenteNewsletterMutation__
 *
 * To run a mutation, you first call `useConfirmUtenteNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUtenteNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUtenteNewsletterMutation, { data, loading, error }] = useConfirmUtenteNewsletterMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmUtenteNewsletterMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmUtenteNewsletterMutation, ConfirmUtenteNewsletterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmUtenteNewsletterMutation, ConfirmUtenteNewsletterMutationVariables>(ConfirmUtenteNewsletterDocument, options);
      }
export type ConfirmUtenteNewsletterMutationHookResult = ReturnType<typeof useConfirmUtenteNewsletterMutation>;
export type ConfirmUtenteNewsletterMutationResult = Apollo.MutationResult<ConfirmUtenteNewsletterMutation>;
export type ConfirmUtenteNewsletterMutationOptions = Apollo.BaseMutationOptions<ConfirmUtenteNewsletterMutation, ConfirmUtenteNewsletterMutationVariables>;
export const OrdiniDocument = gql`
    query Ordini {
  ordini {
    edges {
      node {
        pk
        data
        codice
        estero
        prezzoTotaleScontato
        spedizioneOrdine {
          tracking
        }
        stato {
          nome
          alias
          chiave
        }
        righeOrdine {
          pk
          tipologia
          quantitaOrdinata
          prodotto {
            pk
            prodotto {
              nomeFantasia
              nome
              mainImage
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useOrdiniQuery__
 *
 * To run a query within a React component, call `useOrdiniQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdiniQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdiniQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrdiniQuery(baseOptions?: Apollo.QueryHookOptions<OrdiniQuery, OrdiniQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdiniQuery, OrdiniQueryVariables>(OrdiniDocument, options);
      }
export function useOrdiniLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdiniQuery, OrdiniQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdiniQuery, OrdiniQueryVariables>(OrdiniDocument, options);
        }
export type OrdiniQueryHookResult = ReturnType<typeof useOrdiniQuery>;
export type OrdiniLazyQueryHookResult = ReturnType<typeof useOrdiniLazyQuery>;
export type OrdiniQueryResult = Apollo.QueryResult<OrdiniQuery, OrdiniQueryVariables>;
export const OrdineDocument = gql`
    query Ordine($pk: Int!) {
  ordine(pk: $pk) {
    pk
    data
    codice
    hasFattura
    hasRicevuta
    includiMessaggio
    estero
    indirizzoSpedizione {
      nome
      cognome
      indirizzo
      numeroCivico
      cap
      citta
      provincia
    }
    indirizzoFatturazione {
      nome
      cognome
      indirizzo
      numeroCivico
      cap
      citta
      provincia
      cf
      piva
    }
    sistemaPagamento {
      nome
    }
    spedizioneOrdine {
      tracking
    }
    stato {
      nome
      alias
      chiave
    }
    prezzoTotale
    prezzoTotaleScontato
    promozioneValoreAssoluto
    promozione {
      alias
    }
    righeOrdine {
      pk
      tipologia
      quantitaOrdinata
      prezzoUnitario
      prezzoUnitarioScontato
      prezzoTotaleScontato
      omaggio
      prodotto {
        pk
        prodotto {
          nomeFantasia
          mainImage
          cantina {
            nome
          }
        }
      }
    }
  }
}
    `;

/**
 * __useOrdineQuery__
 *
 * To run a query within a React component, call `useOrdineQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdineQuery({
 *   variables: {
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useOrdineQuery(baseOptions: Apollo.QueryHookOptions<OrdineQuery, OrdineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdineQuery, OrdineQueryVariables>(OrdineDocument, options);
      }
export function useOrdineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdineQuery, OrdineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdineQuery, OrdineQueryVariables>(OrdineDocument, options);
        }
export type OrdineQueryHookResult = ReturnType<typeof useOrdineQuery>;
export type OrdineLazyQueryHookResult = ReturnType<typeof useOrdineLazyQuery>;
export type OrdineQueryResult = Apollo.QueryResult<OrdineQuery, OrdineQueryVariables>;
export const GetPaginaDocument = gql`
    query getPagina($chiave: String!, $contentTypeId: Int!, $objectId: Int!) {
  pagina(chiave: $chiave, contentTypeId: $contentTypeId, objectId: $objectId) {
    adminUrl
    breadcrumbs {
      titolo
      url
    }
    seo {
      title
      description
      image
    }
    chiave
  }
}
    `;

/**
 * __useGetPaginaQuery__
 *
 * To run a query within a React component, call `useGetPaginaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginaQuery({
 *   variables: {
 *      chiave: // value for 'chiave'
 *      contentTypeId: // value for 'contentTypeId'
 *      objectId: // value for 'objectId'
 *   },
 * });
 */
export function useGetPaginaQuery(baseOptions: Apollo.QueryHookOptions<GetPaginaQuery, GetPaginaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginaQuery, GetPaginaQueryVariables>(GetPaginaDocument, options);
      }
export function useGetPaginaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginaQuery, GetPaginaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginaQuery, GetPaginaQueryVariables>(GetPaginaDocument, options);
        }
export type GetPaginaQueryHookResult = ReturnType<typeof useGetPaginaQuery>;
export type GetPaginaLazyQueryHookResult = ReturnType<typeof useGetPaginaLazyQuery>;
export type GetPaginaQueryResult = Apollo.QueryResult<GetPaginaQuery, GetPaginaQueryVariables>;
export const SetProdottiDocument = gql`
    query SetProdotti($chiave: String!) {
  setProdotti(chiave: $chiave) {
    pk
    nome
    nomeFantasia
    alias
    slug
  }
}
    `;

/**
 * __useSetProdottiQuery__
 *
 * To run a query within a React component, call `useSetProdottiQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetProdottiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetProdottiQuery({
 *   variables: {
 *      chiave: // value for 'chiave'
 *   },
 * });
 */
export function useSetProdottiQuery(baseOptions: Apollo.QueryHookOptions<SetProdottiQuery, SetProdottiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetProdottiQuery, SetProdottiQueryVariables>(SetProdottiDocument, options);
      }
export function useSetProdottiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetProdottiQuery, SetProdottiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetProdottiQuery, SetProdottiQueryVariables>(SetProdottiDocument, options);
        }
export type SetProdottiQueryHookResult = ReturnType<typeof useSetProdottiQuery>;
export type SetProdottiLazyQueryHookResult = ReturnType<typeof useSetProdottiLazyQuery>;
export type SetProdottiQueryResult = Apollo.QueryResult<SetProdottiQuery, SetProdottiQueryVariables>;
export const ProdottoDocument = gql`
    query Prodotto($slug: String!) {
  prodotto(slug: $slug) {
    redirect {
      pk
      url
    }
    statusCodeRedirect
    prodotto {
      contentTypeId
      modelClass
      pk
      adminUrl
      varianteProdotto {
        pk
      }
      slug
      alias
      nomeFantasia
      nome
      descrizione
      mainImage
      url
      breadcrumbs {
        titolo
        url
      }
      codice
      prezzo
      prezzoOmnibus
      prezzoScontato
      scontoPercentuale
      promoApplicata {
        percentuale
        valore
        alias
        descrizione
        dal
        al
        timeUntil
        mostraCounter
      }
      omaggio {
        nome
        descrizione
        prodottoOmaggio {
          prodotto {
            alias
            nome
            mainImage
          }
        }
      }
      inWishlist
      disponibilita
      acquistabile
      url
      mondo
      bundle
      numeroPezziBundle
      prodottiBundle {
        variante {
          prodotto {
            pk
            nomeFantasia
            nome
            descrizione
            mainImage
            url
          }
        }
        quantita
      }
      magnum
      categoria {
        alias
      }
      cantina {
        nome
        slug
      }
      attributi {
        annata
        vitigno
        abbinamento
        gradazione
        formato
        colore
        consumoIdeale
        servizio
      }
      datiSchedaTecnica {
        chiave
        alias
        testo
        titolo
      }
      voti {
        premio {
          nome
          sigla
          chiave
        }
        testo
        valore
        annata
        ordinamento
        id
      }
    }
  }
}
    `;

/**
 * __useProdottoQuery__
 *
 * To run a query within a React component, call `useProdottoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProdottoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProdottoQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProdottoQuery(baseOptions: Apollo.QueryHookOptions<ProdottoQuery, ProdottoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProdottoQuery, ProdottoQueryVariables>(ProdottoDocument, options);
      }
export function useProdottoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProdottoQuery, ProdottoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProdottoQuery, ProdottoQueryVariables>(ProdottoDocument, options);
        }
export type ProdottoQueryHookResult = ReturnType<typeof useProdottoQuery>;
export type ProdottoLazyQueryHookResult = ReturnType<typeof useProdottoLazyQuery>;
export type ProdottoQueryResult = Apollo.QueryResult<ProdottoQuery, ProdottoQueryVariables>;
export const LoginDocument = gql`
    mutation login($username: String!, $password: String!) {
  tokenAuth(username: $username, password: $password) {
    token
    user {
      pk
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const SocialAuthDocument = gql`
    mutation SocialAuth($provider: String!, $accessToken: String!) {
  socialAuth(provider: $provider, accessToken: $accessToken) {
    social {
      uid
      user {
        pk
      }
    }
    token
  }
}
    `;
export type SocialAuthMutationFn = Apollo.MutationFunction<SocialAuthMutation, SocialAuthMutationVariables>;

/**
 * __useSocialAuthMutation__
 *
 * To run a mutation, you first call `useSocialAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialAuthMutation, { data, loading, error }] = useSocialAuthMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useSocialAuthMutation(baseOptions?: Apollo.MutationHookOptions<SocialAuthMutation, SocialAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SocialAuthMutation, SocialAuthMutationVariables>(SocialAuthDocument, options);
      }
export type SocialAuthMutationHookResult = ReturnType<typeof useSocialAuthMutation>;
export type SocialAuthMutationResult = Apollo.MutationResult<SocialAuthMutation>;
export type SocialAuthMutationOptions = Apollo.BaseMutationOptions<SocialAuthMutation, SocialAuthMutationVariables>;
export const CreateUtenteDocument = gql`
    mutation createUtente($input: UtenteInput!) {
  createUtente(input: $input) {
    status
    utente {
      pk
      dataRegistrazione
    }
  }
}
    `;
export type CreateUtenteMutationFn = Apollo.MutationFunction<CreateUtenteMutation, CreateUtenteMutationVariables>;

/**
 * __useCreateUtenteMutation__
 *
 * To run a mutation, you first call `useCreateUtenteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUtenteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUtenteMutation, { data, loading, error }] = useCreateUtenteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUtenteMutation(baseOptions?: Apollo.MutationHookOptions<CreateUtenteMutation, CreateUtenteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUtenteMutation, CreateUtenteMutationVariables>(CreateUtenteDocument, options);
      }
export type CreateUtenteMutationHookResult = ReturnType<typeof useCreateUtenteMutation>;
export type CreateUtenteMutationResult = Apollo.MutationResult<CreateUtenteMutation>;
export type CreateUtenteMutationOptions = Apollo.BaseMutationOptions<CreateUtenteMutation, CreateUtenteMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($token: String!, $password: String!) {
  resetPassword(password: $password, token: $token) {
    status
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const RecuperaPasswordDocument = gql`
    mutation recuperaPassword($email: String!) {
  recuperaPassword(email: $email) {
    status
  }
}
    `;
export type RecuperaPasswordMutationFn = Apollo.MutationFunction<RecuperaPasswordMutation, RecuperaPasswordMutationVariables>;

/**
 * __useRecuperaPasswordMutation__
 *
 * To run a mutation, you first call `useRecuperaPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecuperaPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recuperaPasswordMutation, { data, loading, error }] = useRecuperaPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRecuperaPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RecuperaPasswordMutation, RecuperaPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecuperaPasswordMutation, RecuperaPasswordMutationVariables>(RecuperaPasswordDocument, options);
      }
export type RecuperaPasswordMutationHookResult = ReturnType<typeof useRecuperaPasswordMutation>;
export type RecuperaPasswordMutationResult = Apollo.MutationResult<RecuperaPasswordMutation>;
export type RecuperaPasswordMutationOptions = Apollo.BaseMutationOptions<RecuperaPasswordMutation, RecuperaPasswordMutationVariables>;
export const ConfirmUtenteDocument = gql`
    mutation confirmUtente($token: String!) {
  confirmUtente(token: $token) {
    status
  }
}
    `;
export type ConfirmUtenteMutationFn = Apollo.MutationFunction<ConfirmUtenteMutation, ConfirmUtenteMutationVariables>;

/**
 * __useConfirmUtenteMutation__
 *
 * To run a mutation, you first call `useConfirmUtenteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUtenteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUtenteMutation, { data, loading, error }] = useConfirmUtenteMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmUtenteMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmUtenteMutation, ConfirmUtenteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmUtenteMutation, ConfirmUtenteMutationVariables>(ConfirmUtenteDocument, options);
      }
export type ConfirmUtenteMutationHookResult = ReturnType<typeof useConfirmUtenteMutation>;
export type ConfirmUtenteMutationResult = Apollo.MutationResult<ConfirmUtenteMutation>;
export type ConfirmUtenteMutationOptions = Apollo.BaseMutationOptions<ConfirmUtenteMutation, ConfirmUtenteMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    pk
    email
    nome
    cognome
    completed
    isStaff
    edit
    privacy
    privacyCommerciale
    dataRegistrazione
    isImpersonate
    stopImpersonateUrl
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UpdateUtenteDocument = gql`
    mutation UpdateUtente($input: UtenteUpdateInput!) {
  updateUtente(input: $input) {
    iscrizioneNewsletter
    utente {
      pk
      nome
      cognome
      email
    }
  }
}
    `;
export type UpdateUtenteMutationFn = Apollo.MutationFunction<UpdateUtenteMutation, UpdateUtenteMutationVariables>;

/**
 * __useUpdateUtenteMutation__
 *
 * To run a mutation, you first call `useUpdateUtenteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUtenteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUtenteMutation, { data, loading, error }] = useUpdateUtenteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUtenteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUtenteMutation, UpdateUtenteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUtenteMutation, UpdateUtenteMutationVariables>(UpdateUtenteDocument, options);
      }
export type UpdateUtenteMutationHookResult = ReturnType<typeof useUpdateUtenteMutation>;
export type UpdateUtenteMutationResult = Apollo.MutationResult<UpdateUtenteMutation>;
export type UpdateUtenteMutationOptions = Apollo.BaseMutationOptions<UpdateUtenteMutation, UpdateUtenteMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($input: PasswordUpdateInput!) {
  updatePassword(input: $input) {
    message
  }
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "CantineNode",
      "CategoriaFaqNode",
      "CategoriaNewsNode",
      "CategoriaNode",
      "EventoNode",
      "FaqNode",
      "IndirizzoNode",
      "MenuItemGivNode",
      "MenuItemNode",
      "MotivazioniContattoNode",
      "NazioneNode",
      "NewsNode",
      "OrdineNode",
      "PaginaNode",
      "ProdottoNode",
      "RigaOrdineNode",
      "SocialNode",
      "SpedizioneOrdineNode",
      "StatoNode",
      "StatoOrdineNode",
      "UtenteNode",
      "ValoreAttributoNode",
      "VarianteProdottoNode",
      "VisitaGivNode",
      "VisitaNode"
    ]
  }
};
      export default result;
    