/* eslint-disable arrow-body-style */
/* eslint-disable @next/next/no-sync-scripts */
/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

const Doofinder = () => {
  const router = useRouter()

  
  const [windowScroll, setWindowScroll] = useState(0)

  const handleScroll = () => {
    const targetElement = document.getElementById('dfclassic')

    
    if (!targetElement) return
  
    if (window.scrollY > 0) {
      targetElement.classList.add('w-scrolled')
    } else {
      targetElement.classList.remove('w-scrolled')
    }
    
    if (window.pageYOffset < windowScroll) {
      targetElement.classList.add('w-scrollup')
    } else {
      targetElement.classList.remove('w-scrollup')
    }
    
    setWindowScroll(window.pageYOffset)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [windowScroll])


  useEffect(() => {
    const loadDoofinderScript = () => {
      const existingScript = document.getElementById('doofinder-script')
      if (existingScript) {
        existingScript.remove()
      }

      const script = document.createElement('script')
      script.id = 'doofinder-script'
      script.async = true
      script.innerHTML = `
        var doofinder_script ='//cdn.doofinder.com/media/js/doofinder-classic.7.latest.min.js';
        (function(d,t){var f=d.createElement(t),s=d.getElementsByTagName(t)[0];f.async=1;
        f.src=('https:'==location.protocol?'https:':'http:')+doofinder_script;
        f.setAttribute('charset','utf-8');
        s.parentNode.insertBefore(f,s)}(document,'script'));

        var dfClassicLayers = [{
          "queryInput": "input[name='q']",
          "hashid": '9e697e6e36977417647ddd9e746d6d8c',
          "zone": "us1",
          "display": {
            "lang": 'it',
          },
          callbacks: {
            loaded: function(instance) {
              instance.configure();
            }
          },
          mobile: {
            maxWidth: 991
          }
        }];
      `
      document.body.appendChild(script)
    }

    loadDoofinderScript()

    const handleRouteChange = () => {
      loadDoofinderScript()
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return null
}

export default Doofinder
