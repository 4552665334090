/* eslint-disable react-hooks/exhaustive-deps */
import App from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from 'gql/client'
import CartContextProvider from 'utils/context'
import 'styles/global.sass'
import { api, isEmpty } from 'utils'
import Router, { useRouter } from 'next/router'
import { useUtente, useLocalStorage } from 'hooks'
import { useEffect, useState } from 'react'
import NProgress from 'nprogress'
import { useDispatch, useSelector } from 'react-redux'
import Head from 'next/head'
import { fetchTraduzioni } from 'containers/traduzioni'
import { setPagina } from 'containers/pagina'
import { trackConnectifPageVisit, executeIfInitialized, updateConnectif } from 'utils/connectif'
import { initGTMdataLayer, updateGTMDataLayer } from 'utils/gtm'
import { Loader } from 'components/atoms'
import Doofinder from 'components/organisms/Doofinder/Doofinder'
import AmazonPayScript from 'components/molecules/AmazonPayScript/AmazonPayScript'
import ConnectifScript from 'components/molecules/ConnectifScript/ConnectifScript'
import { wrapper } from '../store'
import 'nprogress/nprogress.css'
import '../public/nprogress.css'
import PromoLinkModal from 'components/organisms/PromoLinkModal/PromoLinkModal'
import { CHECK_REVALIDATE } from 'utils/endpoint'
import { NewsletterModal } from 'components/organisms'
import { GoogleOAuthProvider } from '@react-oauth/google'
import Script from 'next/script'

const PageWrapper = ({ loginRequired = false, pageProps, children }) => {
  const router = useRouter()
  const { pagina } = useSelector((state: any) => state?.pagina)
  const { utente, loading } = useUtente()
  const [cookieMailchimp, setCookieMailchimp] = useState(false)
  const [showSocialLogin, setShowSocialLogin] = useLocalStorage('showSocialLogin', true)
  const dispatch = useDispatch()

  useEffect(() => {
    initGTMdataLayer()
  }, [])

  useEffect(() => {
    if (isEmpty(pageProps)) {
      dispatch(setPagina({ pagina: { chiave: '404', titolo: '404' } }))
    } else {
      dispatch(setPagina(pageProps))
    }
  }, [router.asPath])

  useEffect(() => {
    if (router.query['mc_cid']) {
      document.cookie = `id_campagna_mailchimp=${router.query['mc_cid']}; path=/; secure`
      setCookieMailchimp(true)
    } else {
      if (cookieMailchimp == false) {
        document.cookie = 'id_campagna_mailchimp=; Max-Age=0; path=/; secure'
      }
    }
    if (showSocialLogin) {
      setShowSocialLogin(true)
      if (router.query['app']) {
        setShowSocialLogin(false)
      }
    }
  }, [router.query])

  useEffect(() => {
    const checkUtente = setTimeout(() => {
      if (!loading) {
        // pagina permessa a utente autenticato -> login
        if (loginRequired && !loading && !utente) router.replace(`/login?next=${router.asPath}`)

        // utente social che non ha completato la registrazione
        // if (utente && !utente.completed && !router.asPath.includes('/registrazione/complete')) {
        //  router.push('/registrazione/complete')
        // }
      }
    }, 300)
    updateConnectif(utente)

    return () => clearTimeout(checkUtente)
  }, [loginRequired, utente, loading])

  useEffect(() => {
    if (!loading && !isEmpty(pagina)) {
      // il caso "prodotto" gestito direttamente in pagina
      executeIfInitialized(trackConnectifPageVisit)
      if (!pagina?.pagina?.chiave?.includes('prodotto')) {
        updateGTMDataLayer(pagina, utente, router)
      }
    }
  }, [loading, pagina])

  return loginRequired ? loading ? <Loader /> : utente ? children : <Loader /> : children
}

NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
})

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const MyApp = ({ Component, pageProps, cookies }: any) => {
  const router = useRouter()
  const apolloStore = useApollo(pageProps, cookies, router.locale)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTraduzioni(router.locale, false))
  }, [])

  useEffect(() => {
    if (pageProps?.revalidate)
      handleCheckRevalidate(router.asPath.split('?')?.[0], pageProps.revalidate)
  }, [pageProps?.revalidate])

  const handleCheckRevalidate = async (path, revalidate) => {
    await api.get(CHECK_REVALIDATE, { params: { path, revalidate } })
  }

  return (
    <ApolloProvider client={apolloStore}>
      <CartContextProvider>
        <GoogleOAuthProvider clientId={process.env.GOOGLE_APP_ID}>
          <PageWrapper loginRequired={Component.loginRequired || false} pageProps={pageProps}>
            <>
              {/* Su next11 indicano di inserire la favicon dentro _app.tsx */}
              <Head>
                <link rel="shortcut icon" href="/images/favicon.ico" />
              </Head>
              <ConnectifScript />
              <Component {...pageProps} />
              <Script
                src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
                strategy="lazyOnload"
              />
            </>
            <Doofinder />
            <AmazonPayScript />
            <PromoLinkModal />
          </PageWrapper>
        </GoogleOAuthProvider>
      </CartContextProvider>
    </ApolloProvider>
  )
}

export default wrapper.withRedux(MyApp)
